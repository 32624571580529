import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  Signal,
  SimpleChanges,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { MyMessageService } from '@alimento-ipv-frontend/ui-lib';
import {
  PurchaseEntry,
  PurchaseEntryCreateEvent,
  SalesEntryEvent,
  SalesInvoiceUpdateEvent,
  SearchSalesInvoice
} from '../../../types/training.type';
import { PurchaseEntryService } from '../../../services/purchase-entries.service';
import { first } from 'rxjs';
import { SimpleBranch } from '../../../types/branch.type';
import {
  PurchaseEntryCreateDialogComponent
} from '../purchase-entry-create-dialog/purchase-entry-create-dialog.component';
import { SessionService } from '../../../services/session.service';
import { SalesInvoiceService } from '../../../services/sales-invoice.service';
import { SALES_INVOICE_ACTION } from '../../../types/training.enum';
import {
  SalesInvoiceActionDialogComponent
} from '../sales-invoice-action-dialog/sales-invoice-action-dialog.component';
import { SalesInvoicePopupComponent } from '../sales-invoice-popup/sales-invoice-popup.component';
import { SimplePerson } from '../../../types/person.type';

@Component({
    selector: 'alimento-ipv-frontend-invoices-overview',
    templateUrl: './invoices-overview.component.html',
    standalone: false
})
export class InvoicesOverviewComponent implements OnChanges {
  @Input()
  trainingId: string;

  @Input()
  readOnly: boolean;

  @Input()
  hasCounselor: boolean;

  @Input()
  purchaseEntries: Signal<PurchaseEntry[]> = signal([]);

  @Input()
  salesInvoices: Signal<SearchSalesInvoice[]> = signal([]);

  @Output()
  purchaseEntryChanges: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  salesInvoiceChanges: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(SalesInvoiceActionDialogComponent)
  approveSalesInvoiceDialog: SalesInvoiceActionDialogComponent;

  @ViewChild(SalesInvoicePopupComponent)
  salesInvoiceDialog: SalesInvoicePopupComponent;

  branches: SimpleBranch[] = [];
  showCreatePurchaseEntriesButton = false;
  persons: SimplePerson[] = []
  currentSalesInvoice: SearchSalesInvoice;
  currentSalesInvoiceAction: SALES_INVOICE_ACTION;
  selectedIndex = 0;
  activeIndex: WritableSignal<string> = signal("0");

  constructor(private messageService: MyMessageService,
              private sessionService: SessionService,
              private purchaseEntryService: PurchaseEntryService,
              private salesInvoiceService: SalesInvoiceService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingId']?.currentValue) {
      this._getPurchaseEntryBranches();
    }

    this._setShowPurchaseEntryButton();
  }

  createPurchaseEntries(createDialog: PurchaseEntryCreateDialogComponent): void {
    createDialog.open();
  }

  executeCreatePurchaseEntries(event: PurchaseEntryCreateEvent, createDialog: PurchaseEntryCreateDialogComponent): void {
    event.setLoading(true);
    this.purchaseEntryService.createPurchaseEntries(this.trainingId, event.branchIds).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success("trainings.purchaseEntriesCreated");
          event.setLoading(false);
          createDialog.closeDialog();
          this._getPurchaseEntryBranches();
          this.purchaseEntryChanges.emit();
        },
        error: () => {
          event.setLoading(false);
          this._getPurchaseEntryBranches();
          createDialog.closeDialog();
          this.purchaseEntryChanges.emit();
        }
      });
  }

  executeSubmitSalesInvoiceActionDialog(event: SalesEntryEvent): void {
    event.setLoading(true);
    if (event.action === SALES_INVOICE_ACTION.reject) {
      this.salesInvoiceService.reject(event.salesInvoice.id, event.data).pipe(first())
        .subscribe({
          next: () => this._afterSalesInvoiceAction(event),
          error: () => event.setLoading(false)
        });
    }
    else if (event.action === SALES_INVOICE_ACTION.approve) {
      this.executeApproveSalesInvoice(event);
    }
  }

  executeApproveSalesInvoice(event: SalesEntryEvent): void {
    event.setLoading(true);
    this.salesInvoiceService.approve(event.salesInvoice.id).pipe(first())
      .subscribe({
        next: () => this._afterSalesInvoiceAction(event),
        error: () => event.setLoading(false)
      });
  }

  private _afterSalesInvoiceAction(event: SalesEntryEvent): void {
    this.salesInvoiceChanges.emit();
    this.messageService.success();
    event.setLoading(false);
    this.approveSalesInvoiceDialog.closeDialog();
    this.salesInvoiceDialog.closePopup();
  }

  private _getPurchaseEntryBranches(): void {
    this.showCreatePurchaseEntriesButton = false;
    this.purchaseEntryService.getPurchaseEntryBranches(this.trainingId).pipe(first())
      .subscribe(branches => {
        this.branches = branches;
        this._setShowPurchaseEntryButton();
      });
  }

  private _setShowPurchaseEntryButton(): void {
    this.showCreatePurchaseEntriesButton = !this.readOnly &&
      this.branches?.length > 0 &&
      this.sessionService.nrOfSessions() > 0 &&
      this.hasCounselor;
  }

  onSaleInvoiceActionClicked(index: number, salesInvoice: SearchSalesInvoice, action: SALES_INVOICE_ACTION): void {
    this.selectedIndex = index;
    this.currentSalesInvoiceAction = action;
    this.currentSalesInvoice = salesInvoice;
    if ([SALES_INVOICE_ACTION.approve, SALES_INVOICE_ACTION.reject].includes(action)) {
      this.approveSalesInvoiceDialog.open(action);
    }
    else {
      this.salesInvoiceService.getSalesInvoice(salesInvoice.id).pipe(first())
        .subscribe(salesInvoiceDetail => {
          salesInvoiceDetail.id = salesInvoice.id;
          this.salesInvoiceDialog.openPopup(salesInvoiceDetail, action);
        });
    }
  }

  salesInvoiceUpdate(event: SalesInvoiceUpdateEvent): void {
    event.setLoading(true);
    this.salesInvoiceService.updateSalesInvoice(event.salesInvoice).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success();
          event.setLoading(false);
          this.salesInvoiceChanges.emit();

          if (event.andApprove) {
            this.executeApproveSalesInvoice({
              setLoading: event.setLoading,
              action: SALES_INVOICE_ACTION.approve,
              salesInvoice: {id: event.salesInvoice.id} as SearchSalesInvoice
            });
          }
          else if (event.andNext) {
            this.changeSelectedInvoiceIndex(1);
          }
          else {
            this.salesInvoiceDialog.closePopup();
          }
        },
        error: () => event.setLoading(false)
      })
  }

  changeSelectedInvoiceIndex(event: number): void {
    const selectedIndex = (this.selectedIndex + event) % this.salesInvoices().length;
    this.onSaleInvoiceActionClicked(selectedIndex, this.salesInvoices()[selectedIndex], this.currentSalesInvoiceAction);
  }
}
