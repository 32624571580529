import { Component, effect, OnDestroy, OnInit, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { first, map, Observable, Subscription } from 'rxjs';
import {
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  NavigationService,
  PaginatedResponse,
  Role,
  SearchComponent,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { SearchPersonItem, TransitionSearchItem } from '../../../types/person.type';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { SearchesService } from '../../../services/searches.service';
import { EmploymentMapper } from '../../../utils/mapper/employment.mapper';
import { BranchService } from '../../../services/branch.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'alimento-ipv-frontend-person-list',
  templateUrl: './person-list.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class PersonListComponent implements OnInit, OnDestroy {

  searchData$: InfiniteScrollDataAdapter<SearchPersonItem>;
  searchTransitionData$: InfiniteScrollDataAdapter<TransitionSearchItem>;
  searchFilters: SearchFilter[];
  searchTransitionFilters: SearchFilter[];
  activeIndex: WritableSignal<string> = signal('0');
  protected readonly Role = Role;
  private _subscription: Subscription;

  constructor(private router: Router,
              private navigationService: NavigationService,
              private activatedRoute: ActivatedRoute,
              private searchesService: SearchesService,
              private referenceDataService: ReferenceDataService,
              private branchService: BranchService,
              private employmentMapper: EmploymentMapper,
              private translationService: TranslationService) {
    effect(() => {
      this.navigationService.replaceState(
        this.router.createUrlTree(
          ['persons'],
          { queryParams: { tabIndex: this.activeIndex() } }
        ).toString()
      );
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(first())
      .subscribe(params => {
        this.activeIndex.set(params['tabIndex'] || '0');
      });

    this._subscription = this.translationService.languageChange$.subscribe(() => {
      this._createSearch();
      this._createTransitionSearch();
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 10,
      filters: []
    };

    searchRequest.filters.push({
      type: FilterType.embed,
      values: ['employments']
    });

    this.searchData$ = new InfiniteScrollDataAdapter<SearchPersonItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<SearchPersonItem>> => {
      return this.searchesService.searchPerson(searchRequest);
    }, searchRequest, true);
    this.searchFilters = [
      {
        type: SearchFilterType.multiselect,
        key: FilterType.employmentRole,
        expanded: true,
        label: 'persons.employments.role',
        data: this.employmentMapper.getEmploymentRoleFilter()
      },
      {
        type: SearchFilterType.multiselect,
        label: 'persons.workStatus',
        key: FilterType.filterWorkStatus,
        expanded: false,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.WORK_STATUSES, FilterType.filterWorkStatus)
      },
      {
        type: SearchFilterType.lazyLoadItem,
        key: FilterType.filterBranches,
        label: 'persons.employments.branch',
        expanded: false,
        dataSource: (searchRequest: SearchRequest) => {
          return this.searchesService.searchBranches(searchRequest)
            .pipe(map((result: PaginatedResponse<any>) => {
              result.data = result.data.map(item => ({
                value: '' + item.branchId,
                label: item.name,
                type: FilterType.filterBranches
              }));
              return result;
            }));
        },
        getItem: (id: string) => this.branchService.getBranch(id)
          .pipe(map(branch => ({
            value: '' + branch.branchId,
            label: branch.branchName,
            type: FilterType.filterBranches
          }) as IFilter))
      }
    ];
  }

  private _createTransitionSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 10,
      filters: []
    };

    this.searchTransitionData$ = new InfiniteScrollDataAdapter<TransitionSearchItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<TransitionSearchItem>> => {
      return this.searchesService.searchTransitions(searchRequest);
    }, searchRequest, true);

    this.searchTransitionFilters = [
      {
        type: SearchFilterType.multiselect,
        label: 'persons.transitions.status',
        expanded: true,
        key: FilterType.filterStatuses,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.TRANSITION_STATUSES, FilterType.filterStatuses)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'persons.transitions.type',
        key: FilterType.filterType,
        expanded: false,
        data: this.referenceDataService.getTransitionTypesFlat()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterType,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'persons.workStatus',
        key: FilterType.filterEmploymentWorkStatuses,
        expanded: false,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.WORK_STATUSES, FilterType.filterEmploymentWorkStatuses)
      },
      {
        type: SearchFilterType.lazyLoadItem,
        key: FilterType.filterEmploymentBranches,
        label: 'persons.employments.branch',
        expanded: false,
        dataSource: (searchRequest: SearchRequest) => {
          return this.searchesService.searchBranches(searchRequest)
            .pipe(map((result: PaginatedResponse<any>) => {
              result.data = result.data.map(item => ({
                value: '' + item.branchId,
                label: item.name,
                type: FilterType.filterEmploymentBranches
              }));
              return result;
            }));
        },
        getItem: (id: string) => this.branchService.getBranch(id)
          .pipe(map(branch => ({
            value: '' + branch.branchId,
            label: branch.branchName,
            type: FilterType.filterEmploymentBranches
          }) as IFilter))
      }
    ];
  }

  beforeSearchRequest = (searchComponent: SearchComponent, searchRequest: SearchRequest) => {
    if (searchComponent.isToggled) {
      searchRequest.filters.push({ type: FilterType.onlyActiveEmployment, values: [true] });
    }
  };
}
