import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'alimento-ipv-frontend-overview-page-layout',
  templateUrl: './overview-page-layout.component.html',
  standalone: false
})
export class OverviewPageLayoutComponent {
  @Input()
  header: string;

  @ViewChild('#navigation-bar')
  navigationBar!: ElementRef<HTMLDivElement>;
}
