<div *ngIf='enrollment'
  [ngClass]='{"!bg-primary-hover border-primary border border-b-primary": activeEnrollmentId === enrollment?.id}'
  class="flex w-full gap-4 px-5 py-3 text-lg cursor-pointer 'border-b border-b-grey-2 hover:bg-primary-hover"
  (click)='this.showExtraInfo = !this.showExtraInfo; $event.stopImmediatePropagation();'
>
  <div class='w-4 h-4'>
    <div
      (keyup.enter)='this.showExtraInfo = !this.showExtraInfo'
      class='flex items-center py-2 text-primary outline-primary'
      tabindex='0'
    >
      <i class="pi pi-angle-{{ showExtraInfo ? 'up' : 'down' }}"></i>
    </div>
  </div>
  <div class='flex flex-grow gap-2 items-center' isEllipsis pTooltip>
    <a [routerLink]='["/persons", enrollment.personId]' class='hover:underline cursor-pointer'>
      {{ (enrollment.lastName || '') + ' ' + (enrollment.firstName || '') }}
    </a>
    <span class='text-sm'
          *ngIf='enrollment.nationalIdentificationNumber'>
      {{ enrollment.nationalIdentificationNumber | insz}}
    </span>
    <span class='text-sm'
          *ngIf='!enrollment.nationalIdentificationNumber && enrollment.birthDate'>
      {{ enrollment.birthDate | date: "dd/MM/yyyy"}}
    </span>
  </div>
  <div class='basis-[240px]' isEllipsis pTooltip>
    <div class='flex items-center gap-2'>
      <span>{{ enrollment.workStatus?.label }}</span>
      <i *ngIf='enrollment.via === CEVORA'
         pTooltip='{{"enrollments.cevora" | translate}}'
         class='bg-alimento-green bg-opacity-30 px-1 rounded not-italic font-bold'>
        C
      </i>
    </div>
  </div>
  <div class='basis-[220px]' isEllipsis pTooltip>
    <span>{{ "trainings.addedOn" | translate: {date: enrollment.subscribed | date : 'dd/MM/yyyy'} }}</span>
  </div>
  <div class='basis-[40px] flex flex-col items-end'>
    <alimento-ipv-frontend-more-actions [actions]='actions'></alimento-ipv-frontend-more-actions>
  </div>
</div>
<div *ngIf='enrollment?.transitions && enrollment.transitions.length > 0' class='flex items-center gap-2 ml-12 mr-16 pr-6'>
  <i class='material-symbols-outlined text-primary'>info</i>
  <div class='flex flex-col w-full'>
    <div class='flex justify-between w-full' *ngFor='let transition of enrollment.transitions'>
      <a class='hover:underline cursor-pointer'
         [routerLink]='["/persons", enrollment.personId]'
         [queryParams]='{transitionId: transition.id}'>
        {{'reimbursement.transition' | translate}}: {{getTransitionLabel(transition.transitionTypeId) | async}}
      </a>
      <span *ngIf='transition.remainingBudget !== null'>
        {{"persons.transitions.remainingBudget" | translate}}: {{transition.remainingBudget | currency:"EUR"}}
      </span>
    </div>
  </div>
</div>

<div *ngIf='showExtraInfo && enrollment' class='flex flex-col gap-2 w-full conditional-fields !pb-4'>
  <div class='grid grid-cols-4 gap-2 w-full px-4'>
    <alimento-ipv-frontend-metadata-item
      *ngIf='isOpenExternOrCustom && enrollment.employmentStartDate'
      [header]='"enrollments.employmentStartDate"'
      [value]='enrollment.employmentStartDate | date:"dd/MM/yyyy"'
    ></alimento-ipv-frontend-metadata-item>

    <alimento-ipv-frontend-metadata-item
      *ngIf='!isOpenExternOrCustom'
      [header]='"enrollments.email"'
      [value]='enrollment.email'
    ></alimento-ipv-frontend-metadata-item>

    <alimento-ipv-frontend-metadata-item
      *ngIf='!isOpenExternOrCustom'
      [header]='"enrollments.phoneNumber"'
      [value]='enrollment.phoneNumber'
    ></alimento-ipv-frontend-metadata-item>

    <alimento-ipv-frontend-metadata-item
      *ngIf='hasContactPerson && !isOpenExternOrCustom'
      [header]='"enrollments.contactPerson"'
    >
      <div class='flex flex-wrap gap-1'
           *ngIf='enrollment.contactLastName || enrollment.contactFirstName || enrollment.contactEmail || enrollment.contactPhoneNumber'>
        <span *ngIf='enrollment.contactLastName || enrollment.contactFirstName'>
          {{(enrollment.contactLastName || '') + " " + (enrollment.contactFirstName || '')}}
        </span>
        <span *ngIf='enrollment.contactEmail'>- {{enrollment.contactEmail}}</span>
        <span *ngIf='enrollment.contactPhoneNumber'>- {{enrollment.contactPhoneNumber}}</span>
      </div>
    </alimento-ipv-frontend-metadata-item>

    <alimento-ipv-frontend-metadata-item
      *ngIf='enrollment.interimOfficeName'
      [header]='"enrollments.interimOffice"'
    >
      <div class='flex flex-wrap gap-1'>
        <span>{{enrollment.interimOfficeName}}</span>
        <span>- {{enrollment.interimOfficeContactLastName}} {{enrollment.interimOfficeContactFirstName}}</span>
        <span *ngIf='enrollment.interimOfficeContactEmail'>- {{enrollment.interimOfficeContactEmail}}</span>
      </div>
    </alimento-ipv-frontend-metadata-item>

    <div class='col-span-2'>
      <alimento-ipv-frontend-metadata-item
        *ngIf='enrollment.comments'
        [header]='"enrollments.comments"'
        [value]='enrollment.comments'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div *ngIf='isCancelled' class='grid grid-cols-4 gap-2 w-full px-4'>
    <alimento-ipv-frontend-metadata-item
      [header]='"enrollments.cancelDialog.reasonCancelled" | translate'
      [value]='reasonCancelled'
    ></alimento-ipv-frontend-metadata-item>
    <div class='col-span-3'>
      <alimento-ipv-frontend-metadata-item
        [header]='"enrollments.cancelDialog.comment" | translate'
        [value]='enrollment?.cancelledComment'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>
</div>
