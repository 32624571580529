import { Component, effect, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { first, map, Observable, Subscription } from 'rxjs';
import {
  FEATURE,
  FeatureFlagService,
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  NavigationService,
  PaginatedResponse,
  Role,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { Training, TrainingSearchItem } from '../../../types/training.type';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { SearchesService } from '../../../services/searches.service';
import { TranslateService } from '@ngx-translate/core';
import { TRAINING_SORT_FIELDS } from '../../../types/training.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { PupilTrainingTypeKey, TeacherTrainingTypeKey, TRAINING_TYPE } from '../../../types/reference-data.enum';
import {
  EnrollmentActionEnum,
  EnrollmentSearchItem,
  EnrollmentService,
  TrainingService
} from '@alimento-ipv-frontend/application-lib';
import { EnrollmentPopupComponent } from '../../../enrollments/components/enrollments-popup/enrollment-popup.component';

@Component({
  selector: 'alimento-ipv-frontend-training-list',
  templateUrl: './training-list.component.html',
  standalone: false
})
export class TrainingListComponent implements OnInit, OnDestroy {
  searchData$: InfiniteScrollDataAdapter<TrainingSearchItem>;
  searchEnrollmentData$: InfiniteScrollDataAdapter<EnrollmentSearchItem>;
  searchFilters: SearchFilter[];
  searchEnrollmentFilters: SearchFilter[];
  sortFilters: IFilter[];
  activeIndex: WritableSignal<string> = signal('0');

  addButtonItems: any[];

  protected readonly FEATURE = FEATURE;
  protected readonly Role = Role;
  private _subscription: Subscription;
  enrollmentTraining: Training;

  constructor(private searchesService: SearchesService,
              private trainingService: TrainingService,
              private enrollmentService: EnrollmentService,
              private referenceDataService: ReferenceDataService,
              private translateService: TranslateService,
              private translationService: TranslationService,
              private featureService: FeatureFlagService,
              private navigationService: NavigationService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    effect(() => {
      this.navigationService.replaceState(
        this.router.createUrlTree(
          ['trainings'],
          { queryParams: { tabIndex: this.activeIndex() } }
        ).toString()
      );
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(first())
      .subscribe(params => {
        this.activeIndex.set(params['tabIndex'] || '0');
      });

    this._subscription = this.translationService.languageChange$.subscribe(() => {
      this._createSearch();
      this._createEnrollmentSearch();

      this.featureService.initialize().subscribe(() => {
        this._createButtonItems();
      });
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: [],
      sortField: TRAINING_SORT_FIELDS.ALIMENTO_ID,
      sortOrder: 0
    };

    this.searchData$ = new InfiniteScrollDataAdapter<TrainingSearchItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<TrainingSearchItem>> => {
      return this.searchesService.searchTrainings(searchRequest);
    }, searchRequest, true);
    this.searchFilters = [
      {
        type: SearchFilterType.date,
        label: 'trainings.sessions.date',
        key: 'date'
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.status',
        key: FilterType.filterStatuses,
        expanded: true,
        data: this.referenceDataService.getTrainingStatuses()
          .pipe(map(types => types.map(type =>
            ({ type: FilterType.filterStatuses, label: type.label, value: type.data }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.type',
        key: FilterType.filterType,
        expanded: true,
        data: this.referenceDataService.getTrainingTypes()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterType,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.published',
        expanded: true,
        key: FilterType.filterIsPublished,
        data: this.referenceDataService.getYesNoFilter(FilterType.filterIsPublished)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.rubric',
        key: FilterType.filterRubric,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.RUBRICS, FilterType.filterRubric)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.caseManager',
        key: FilterType.filterCaseManager,
        data: this.referenceDataService.getCaseManagers(true)
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterCaseManager,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.toApprove',
        key: FilterType.filterToApprove,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.TRAINING_APPROVAL_FILTERS, FilterType.filterToApprove)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.recognizedFor',
        key: FilterType.filterTrainingRecognitionTypes,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_TYPES, FilterType.filterTrainingRecognitionTypes)
      }
    ];

    this.sortFilters = [
      {
        type: 'sort',
        label: this.translateService.instant('trainings.sort.alimentoId'),
        value: TRAINING_SORT_FIELDS.ALIMENTO_ID
      },
      {
        type: 'sort',
        label: this.translateService.instant('trainings.sort.startDate'),
        value: TRAINING_SORT_FIELDS.START_DATE
      }
    ];
  }

  private _createEnrollmentSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };

    this.searchEnrollmentData$ = new InfiniteScrollDataAdapter<EnrollmentSearchItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<EnrollmentSearchItem>> => {
      return this.searchesService.searchEnrollments(searchRequest);
    }, searchRequest, true);
    this.searchEnrollmentFilters = [
      {
        type: SearchFilterType.date,
        label: 'trainings.sessions.date',
        key: 'date'
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.trainingStatus',
        key: FilterType.filterTrainingStatuses,
        expanded: true,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.TRAINING_STATUSES, FilterType.filterTrainingStatuses)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.enrollmentStatus',
        key: FilterType.filterStatuses,
        expanded: true,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.ENROLLMENT_STATUS, FilterType.filterStatuses)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.type',
        key: FilterType.filterTrainingTypes,
        expanded: false,
        data: this.referenceDataService.getTrainingTypes()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterTrainingTypes,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.rubric',
        expanded: false,
        key: FilterType.filterTrainingRubrics,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.RUBRICS, FilterType.filterTrainingRubrics)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.caseManager',
        expanded: false,
        key: FilterType.filterTrainingCaseManagers,
        data: this.referenceDataService.getCaseManagers(true)
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterTrainingCaseManagers,
              label: type.label,
              value: type.data
            }) as IFilter)))
      }
    ];
  }

  private _createButtonItems(): void {
    this.referenceDataService.getTrainingTypes(true).pipe(first())
      .subscribe(trainingTypes => {
        this.addButtonItems = [
          {
            label: trainingTypes.filter(type => type.data === TRAINING_TYPE.OPEN_TRAINING)[0].label,
            command: () => this.router.navigate(['trainings', 'open', 'create'])
          },
          {
            label: trainingTypes.filter(type => type.data === TRAINING_TYPE.CEVORA)[0].label,
            command: () => this.router.navigate(['trainings', 'cevora', 'create'])
          }
        ];

        if (this.featureService.featureOn(FEATURE.EDUCATIONAL_TRAINING)) {
          [
            TRAINING_TYPE.EDUCATION_EVENT,
            TRAINING_TYPE.EDUCATION_COMPANY_VISIT,
            TRAINING_TYPE.EDUCATION_INFO_SESSION,
            TRAINING_TYPE.EDUCATION_INITIATIVE
          ]
            .forEach(educationType => {
              this.addButtonItems.push({
                label: trainingTypes.filter(type => type.data === educationType)[0].label,
                command: () => this.router.navigate(['trainings', 'training-event', 'create'], {
                  queryParams: {
                    type: educationType
                  }
                })
              });
            });

          this.addButtonItems.push({
            label: trainingTypes.filter(type => type.data === PupilTrainingTypeKey)[0].label,
            command: () => this.router.navigate(['trainings', 'training-project-pupil', 'create'])
          });
          this.addButtonItems.push({
            label: trainingTypes.filter(type => type.data === TeacherTrainingTypeKey)[0].label,
            command: () => this.router.navigate(['trainings', 'training-project-teacher', 'create'])
          });
        }
      });
  }

  openEnrollmentPopup(enrollment: EnrollmentSearchItem, enrollmentPopup: EnrollmentPopupComponent): void {
    this.trainingService.getTraining(enrollment.training.id, enrollment.training.typeId).pipe(first())
      .subscribe(training => {
        this.enrollmentTraining = training;

        this.enrollmentService
          .getEnrollment(enrollment.id)
          .pipe(first())
          .subscribe(enrollment => {
            enrollmentPopup.openPopup(enrollment, EnrollmentActionEnum.view);
          });
      });
  }
}
