import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SalesInvoice } from '../types/training.type';
import { APP_CONFIG_TOKEN, AppConfig } from '@alimento-ipv-frontend/ui-lib';

@Injectable({
  providedIn: 'root'
})
export class SalesInvoiceService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getSalesInvoice(saleInvoiceId: string): Observable<SalesInvoice> {
    return this.http.get<SalesInvoice>(`${this.config.readApiUrl}/salesinvoices/${saleInvoiceId}`);
  }

  updateSalesInvoice(salesInvoice: SalesInvoice): Observable<any> {
    return this.http.put<any>(`${this.config.writeApiUrl}/salesinvoices/${salesInvoice.id}`, salesInvoice);
  }

  approve(salesInvoiceId: string): Observable<any> {
    return this.http.put<any>(`${this.config.writeApiUrl}/salesinvoices/${salesInvoiceId}/approve`, {});
  }

  reject(salesInvoiceId: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.config.writeApiUrl}/salesinvoices/${salesInvoiceId}/reject`, data);
  }
}
