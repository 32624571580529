<p-dialog
  (blur)="popupVisible = false"
  *ngIf='popupVisible'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  [closable]="false"
  [focusOnShow]='false'
  appendTo="body"
  header="{{ title | translate }}"
>
  <div *ngIf="trainingPlan" [formGroup]="formGroup">
    <div class="grid grid-cols-2 gap-4 w-fit">
      <div class="w-[202px]">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='true'
          [errorTemplate]='dateErrorTemplate'
          formField='validFrom'
          fieldKey='branches.trainingPlans.validFrom'
        >
          <lib-date-picker
            formControlName="validFrom"
            id="validFrom"
            [pAutoFocus]="true"
          ></lib-date-picker>

          <ng-template #dateErrorTemplate>
            <small *ngIf='formGroup.get("validFrom")?.hasError("required")'>
              {{ 'trainings.sessions.dateRequired' | translate }}
            </small>
            <small *ngIf='formGroup.get("validFrom").hasError("invalidDate")'>
              {{ 'validation.invalidDate' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
      <div class="w-[202px]">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='true'
          [errorTemplate]='dateErrorTemplate2'
          formField='validTo'
          fieldKey='branches.trainingPlans.validTo'
        >
          <lib-date-picker
            [showIcon]="true"
            [showOnFocus]='false'
            [keepInvalid]='true'
            formControlName="validTo"
            id="validTo"
          ></lib-date-picker>

          <ng-template #dateErrorTemplate2>
            <small *ngIf='formGroup.get("validTo")?.hasError("required")'>
              {{ 'trainings.sessions.dateRequired' | translate }}
            </small>
            <small *ngIf='formGroup.get("validTo").hasError("invalidDate")'>
              {{ 'validation.invalidDate' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
      <div class="w-[202px]">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [errorTemplate]='dateErrorTemplate3'
          formField='due'
          fieldKey='branches.trainingPlans.due'
        >
          <lib-date-picker
            [showIcon]="true"
            [showOnFocus]='false'
            [keepInvalid]='true'
            formControlName="due"
            id="due"
          ></lib-date-picker>

          <ng-template #dateErrorTemplate3>
            <small *ngIf='formGroup.get("due")?.hasError("required")'>
              {{ 'trainings.sessions.dateRequired' | translate }}
            </small>
            <small *ngIf='formGroup.get("due").hasError("invalidDate")'>
              {{ 'validation.invalidDate' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>
    <small *ngIf="formGroup.get('validTo')?.hasError('minValueToLow')" class="p-error block">
      {{ 'branches.trainingPlans.validToToLow' | translate }}
    </small>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      fieldKey='branches.trainingPlans.extra'
    >
      <div class='flex flex-col gap-2'>

        <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                          [required]='true'
                                          labelClass='w-[202px]'
                                          formGroupClass='grid grid-cols-2 items-center w-fit gap-4 !pb-1'
                                          formInputClass='!w-[202px]'
                                          formField='moreThan20Employees'
                                          fieldKey='branches.trainingPlans.moreThan20Employees'
        >
          <span *ngIf='formGroup.disabled'>{{ '' + trainingPlan.moreThan20Employees | translate}}</span>

          <div class='w-full'>
            <p-select
              *ngIf='formGroup.enabled'
              [options]="yesNoValues"
              appendTo="body"
              formControlName="moreThan20Employees"
              id="moreThan20Employees"
              optionLabel="label"
              optionValue="data"
              placeholder="{{ 'enrollments.chooseOption' | translate }}"
              styleClass="w-full"
            ></p-select>
          </div>
        </alimento-ipv-frontend-form-field>

        <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                          [required]='true'
                                          labelClass='w-[202px]'
                                          formGroupClass='grid grid-cols-2 items-center w-fit gap-4 !pb-1'
                                          formInputClass='!w-[202px]'
                                          formField='validConsultativeBody'
                                          fieldKey='branches.trainingPlans.validConsultativeBody'
        >
          <span *ngIf='formGroup.disabled'>{{ '' + trainingPlan.validConsultativeBody | translate}}</span>

          <div class='w-full'>
            <p-select
              *ngIf='formGroup.enabled'
              [options]="yesNoValues"
              appendTo="body"
              formControlName="validConsultativeBody"
              id="validConsultativeBody"
              optionLabel="label"
              optionValue="data"
              placeholder="{{ 'enrollments.chooseOption' | translate }}"
              styleClass="w-full"
            ></p-select>
          </div>
        </alimento-ipv-frontend-form-field>

        <div [hidden]='formGroup.get("validConsultativeBody").value !== true'>
          <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                            [required]='true'
                                            labelClass='w-[202px]'
                                            formGroupClass='grid grid-cols-2 items-center w-fit gap-4 !pb-1'
                                            formInputClass='!w-[202px]'
                                            formField='validReceptionPolicy'
                                            fieldKey='branches.trainingPlans.validReceptionPolicy'
          >
            <span *ngIf='formGroup.disabled'>{{ '' + trainingPlan.validReceptionPolicy | translate}}</span>

            <div class='w-full'>
              <p-select
                *ngIf='formGroup.enabled'
                [options]="yesNoValues"
                appendTo="body"
                formControlName="validReceptionPolicy"
                id="validReceptionPolicy"
                optionLabel="label"
                optionValue="data"
                placeholder="{{ 'enrollments.chooseOption' | translate }}"
                styleClass="w-full"
              ></p-select>
            </div>
          </alimento-ipv-frontend-form-field>

          <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                            [required]='true'
                                            labelClass='w-[202px]'
                                            formGroupClass='grid grid-cols-2 items-center w-fit gap-4 !pb-1 mt-2'
                                            formInputClass='!w-[202px]'
                                            formField='consultedSyndic'
                                            fieldKey='branches.trainingPlans.consultedSyndic'
          >
            <span *ngIf='formGroup.disabled'>{{ '' + trainingPlan.consultedSyndic | translate}}</span>

            <div class='w-full'>
              <p-select
                *ngIf='formGroup.enabled'
                [options]="yesNoValues"
                appendTo="body"
                formControlName="consultedSyndic"
                id="consultedSyndic"
                optionLabel="label"
                optionValue="data"
                placeholder="{{ 'enrollments.chooseOption' | translate }}"
                styleClass="w-full"
              ></p-select>
            </div>
          </alimento-ipv-frontend-form-field>
        </div>

        <div class='field-checkbox'>
          <p-checkbox
            formControlName='completePlanReceived'
            [binary]='true'
            inputId='completePlanReceived'
          ></p-checkbox>
          <label for='completePlanReceived'>{{'branches.trainingPlans.completePlanReceived' | translate}}</label>
        </div>

        <div class='field-checkbox'>
          <p-checkbox
            formControlName='statementReceived'
            [binary]='true'
            inputId='statementReceived'
          ></p-checkbox>
          <label for='statementReceived'>{{'branches.trainingPlans.statementReceived' | translate}}</label>
        </div>
      </div>
    </alimento-ipv-frontend-form-field>

    <div class='mb-2'>
      <lib-documents [(files)]='files'
                     [bigIconSize]='true'
                     [canDelete]='true'
                     (filesChange)='filesChange($event)'
      ></lib-documents>
      <small *ngIf="fileRequired && formGroup.get('files').touched && formGroup.get('files')?.errors?.['minLength']" class='p-error block'>
        {{ 'validation.minOneFile' | translate }}
      </small>
    </div>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='comments'
      fieldKey='branches.trainingPlans.comments'
    >
      <textarea
        class="w-full"
        formControlName="comments"
        id="comments"
        pTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        *ngIf="trainingPlan"
        [loading]="loading"
        (onClick)="addOrUpdateTrainingPlan()"
        label="{{ ( trainingPlan.id ? 'branches.trainingPlans.actions.edit' : 'branches.trainingPlans.actions.create') | translate }}"
      ></p-button>
      <p-button
        *ngIf="trainingPlan?.stateId === TRAINING_PLAN_STATE.REQUESTED"
        [loading]="loading"
        (onClick)="addOrUpdateTrainingPlan(TrainingPlanActionEnum.register)"
        label="{{ 'branches.trainingPlans.register' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closePopup()"
        label="{{ 'branches.trainingPlans.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
