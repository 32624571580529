import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TransitionSearchItem, TransitionType } from '../../../types/person.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { combineLatest, first, map } from 'rxjs';
import { TRANSITION_STATE, TRANSITION_TYPE } from '../../../types/person.enum';

@Component({
  selector: 'alimento-ipv-frontend-transition-search-card',
  templateUrl: './transition-search-card.component.html',
  standalone: false
})
export class TransitionSearchCardComponent implements OnChanges {
  @Input()
  transition: TransitionSearchItem;

  type: TransitionType;
  rubrics: string;
  workStatus: string;
  status: string;

  protected readonly TRANSITION_STATE = TRANSITION_STATE;
  protected readonly TRANSITION_TYPE = TRANSITION_TYPE;

  constructor(private referenceDataService: ReferenceDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['transition']?.currentValue) {
      this.referenceDataService.getTransitionType(this.transition.typeId).pipe(first())
        .subscribe(type => {
          this.type = type;
        });

      if (this.transition?.rubricIds) {
        combineLatest(this.transition.rubricIds
          .map(rubricId => this.referenceDataService.getTrainingRubric(rubricId).pipe(first(),
            map(rubric => rubric.label))))
          .subscribe(rubrics => this.rubrics = rubrics.join(', '));
      }

      if (this.transition.employment.workStatusId) {
        this.referenceDataService.getWorkStatus(this.transition.employment.workStatusId).pipe(first())
          .subscribe(workStatus => this.workStatus = workStatus.label);
      }

      this.referenceDataService.getTransitionStatus(this.transition?.statusId).pipe(first())
        .subscribe(status => this.status = status.label);
    }
  }
}
