<div class="relative" dragAndDrop (fileDropped)="onFileDropped($event)" [ngClass]='{"min-h-[80px]": canAdd}'>
  <div id="fileDropZone"
       *ngIf="canAdd"
       class="bg-white flex items-center border-2 border-dashed invisible absolute z-10 w-full h-full">
    <span class="text-xl mx-8">
      {{ 'fileDropZone' | translate }}
    </span>
  </div>
  <input type="file" class="hidden" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event, fileDropRef)" />
  <div class="flex flex-wrap items-start w-full" [ngClass]='{"flex-col" : showFilesAsList, "gap-4 gap-y-2": !showFilesAsList}'>
    <div *ngFor="let file of files; let index = index"
         (click)="openFile(file, $event)"
         [ngClass]="{
         'cursor-pointer hover:underline' : !file.loading,
         'max-w-40 flex-col p-3 pb-2': bigIconSize,
         'gap-2 p-1': !bigIconSize
         }"
         class="flex items-center relative hover:bg-primary-hover">
      <i class="font-material absolute top-1 right-1 text-red" *ngIf="canDelete" (click)="deleteFile(file, $event)">delete</i>
      <i class="{{bigIconSize ? 'text-3xl' : ''}}" [ngClass]="getImgClass(file)"></i>
      <span class="break-all">{{file.fileName}}</span>

      <div *ngIf="file.loading"
           class="absolute top-0 left-0 bg-grey-1 bg-opacity-50 z-20 w-full h-full flex flex-col items-center justify-center">
        <i class="animate-spin font-material text-3xl">progress_activity</i>
      </div>
    </div>
  </div>

  <div class='mt-2'>
    <p-button *ngIf="canAdd" icon='pi pi-upload' (onClick)="fileDropRef.click()" label="{{'addDocument' | translate}}">
    </p-button>
  </div>
</div>
