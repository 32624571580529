import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, Subscription } from 'rxjs';
import { MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { SalesInvoice, SalesInvoiceUpdateEvent } from '../../../types/training.type';
import { SALES_INVOICE_ACTION, SALES_INVOICE_STATUS } from '../../../types/training.enum';
import { Branch } from '../../../types/branch.type';
import { BranchService } from '../../../services/branch.service';
import { EnrollmentDetail } from '../../../types/enrollment.type';
import { EnrollmentService } from '../../../services/enrollment.service';
import { BranchesMapper } from '../../../utils/mapper/branches.mapper';
import { GlobalMapper } from '../../../utils/mapper/global.mapper';

@Component({
    selector: 'alimento-ipv-frontend-sales-invoice-popup',
    templateUrl: './sales-invoice-popup.component.html',
    standalone: false
})
export class SalesInvoicePopupComponent implements OnDestroy {
  @Output()
  formSubmit = new EventEmitter<SalesInvoiceUpdateEvent>();

  @Output()
  changeSelectedInvoiceIndex = new EventEmitter<number>();

  salesInvoice: SalesInvoice;
  salesInvoiceAction: SALES_INVOICE_ACTION;
  enrollment: EnrollmentDetail;
  branch: Branch;
  branchAddress: string;
  invoiceAddress: string;
  personAddress: string;

  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;
  type: string;
  readOnly = false;
  amount: 0;

  private _subscriptions: (Subscription | undefined)[] = [];
  showMoreAdministrativeRules = false;
  canApprove = false;

  constructor(private fb: FormBuilder,
              private messageService: MyMessageService,
              private enrollmentService: EnrollmentService,
              private branchService: BranchService,
              private branchMapper: BranchesMapper) {
  }

  private _getData(): void {
    this.enrollmentService.getEnrollment(this.salesInvoice.service.id).pipe(first())
      .subscribe(enrollment => {
        this.enrollment = enrollment;

        this.canApprove = this.salesInvoice.stateId !== SALES_INVOICE_STATUS.APPROVED;
        this._setFormState();
        if (enrollment.enrollment.branch) {
          this.branchService.getBranch(enrollment.enrollment.branch.data).pipe(first())
            .subscribe(branch => {
              this.branch = branch;
              this.branchAddress = this.branchMapper.getBranchAddress(branch);
              this._setInvoiceReferenceRequiredValidation();
            });
        }
        else {
          this.branch = undefined;
          this.branchAddress = " ";
        }

        this.invoiceAddress = GlobalMapper.getAddress(
          enrollment.enrollment?.invoiceStreet,
          enrollment.enrollment?.invoiceHouseNumber,
          enrollment.enrollment?.invoiceMailBox,
          enrollment.enrollment?.invoicePostalCode,
          enrollment.enrollment?.invoiceCity);
        this.personAddress = GlobalMapper.getAddress(
          enrollment.person?.street,
          enrollment.person?.houseNumber,
          enrollment.person?.mailbox,
          enrollment.person?.postalCode,
          enrollment.person?.city
        )
      });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(salesInvoice: SalesInvoice, salesInvoiceAction: SALES_INVOICE_ACTION): void {
    this.popupVisible = true;
    this.salesInvoice = salesInvoice;
    this.salesInvoiceAction = salesInvoiceAction;
    this._getData();
    this.createForm();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  getFormData(): SalesInvoice {
    return JSON.parse(JSON.stringify(this.formGroup.value));
  }

  updateSalesInvoice(andNext: boolean, andApprove: boolean): void {
    if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    const data = this.getFormData();

    this.formSubmit.emit({
      salesInvoice: data,
      andNext: andNext,
      andApprove: andApprove,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  toggleShowMoreAdministrativeRules(): void {
    this.showMoreAdministrativeRules = !this.showMoreAdministrativeRules;
  }

  hasOverflow(element: HTMLDivElement): boolean {
    return element.scrollHeight > element.clientHeight;
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      id: [this.salesInvoice.id],
      cost: [this.salesInvoice.cost, [Validators.required, Validators.min(0.1)]],
      notes: [this.salesInvoice.notes],
      invoiceReference: [this.salesInvoice.invoiceReference]
    });

    this._setFormState();
    this._setInvoiceReferenceRequiredValidation();

    this.formGroup.get("cost").valueChanges?.subscribe(() => this._updateAmount());
    this._updateAmount();
  }

  private _updateAmount(): void {
    this.amount = this.formGroup.get("cost").value;
  }

  private _setFormState(): void {
    this.readOnly = this.salesInvoiceAction === SALES_INVOICE_ACTION.view || !this.canApprove;
    if (this.readOnly) {
      this.formGroup.disable();
    }
    else {
      this.formGroup.enable();
    }
  }

  private _setInvoiceReferenceRequiredValidation(): void {
    if (this.branch && this.formGroup) {
      this.formGroup.get("invoiceReference").setValidators(this.branch.invoiceReferenceRequired ? [Validators.required] : []);
    }
  }
}
