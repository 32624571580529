<div class="sticky z-10 flex bg-white border-b border-grey-2 top-[65px]">
  <div class="grid grid-cols-[250px_minmax(0,_1fr)] lg:grid-cols-[250px_minmax(0,_1fr)_250px] grid-flow-col-dense w-full gap-8 py-2 px-8 my-0 mx-0 2xl:mx-40">
    <div class="flex items-end">
      <p-button (onClick)='navigateBack($event)'
                icon='pi pi-arrow-left'
                [link]='true'
                label='{{ navigateText | translate }}'
      >
      </p-button>
    </div>

    <div class="flex flex-col flex-grow">
      <h1 class="mt-0 text-text">{{ header }}</h1>

      <div class="flex items-center justify-between">
        <div class='flex items-center gap-4'>
          <lib-breadcrumb *ngIf='breadcrumb?.length > 0' [items]='breadcrumb'></lib-breadcrumb>
          <ng-content select='[status]'></ng-content>
        </div>
        <ng-content select="[top-action]"></ng-content>
      </div>
    </div>

    <div class="flex items-end">
      <ng-content select="[top-right]"></ng-content>
    </div>
  </div>
</div>

<div class="flex">
  <div class="grid grid-cols-[250px_minmax(0,_1fr)] lg:grid-cols-[250px_minmax(0,_1fr)_250px] grid-flow-col-dense w-full gap-8 px-8 py-4 my-0 mx-0 2xl:mx-40">
    <div class="flex flex-col gap-4">
      <ng-content select="[navigation]"></ng-content>
      <div *ngIf='smallSize' class='mt-4'>
        <ng-container *ngTemplateOutlet='metaData'></ng-container>
      </div>
    </div>

    <div class="flex flex-col bg-white p-8 pt-0 border shadow-md">
      <ng-content select="[content]"></ng-content>
    </div>

    <div class="flex flex-col gap-4" *ngIf='!smallSize'>
      <ng-container *ngTemplateOutlet='metaData'></ng-container>
    </div>
  </div>
</div>

<ng-template #metaData>
  <ng-content select="[metadata]"></ng-content>
</ng-template>

