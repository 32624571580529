import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';
import { FormComponent } from '@alimento-ipv-frontend/ui-lib';
import { first } from 'rxjs';
import { EnrollmentDetail, ExtraMailResponse } from '../../../types/enrollment.type';
import { ENROLLMENT_VIA } from '../../../types/enrollment.enum';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { MailType } from '../../../types/reference-data.enum';

@Component({
    selector: 'alimento-ipv-frontend-comments-form',
    templateUrl: './enrollment-comments-form.component.html',
    styleUrls: ['./enrollment-comments-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: EnrollmentCommentsFormComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: EnrollmentCommentsFormComponent,
        },
        { provide: FormComponent, useExisting: EnrollmentCommentsFormComponent },
    ],
    standalone: false
})
export class EnrollmentCommentsFormComponent extends FormComponent implements ControlValueAccessor, OnChanges, Validator {

  @ViewChild("focusElement")
  focusElement: ElementRef;

  @Input()
  extraMail: ExtraMailResponse;

  @Input()
  enrollmentVia: ENROLLMENT_VIA;

  invoiceStatusReadOnly: string;

  protected readonly ENROLLMENT_VIA = ENROLLMENT_VIA;

  constructor(private formBuilder: FormBuilder, private referenceDataService: ReferenceDataService) {
    super();
    this.createFormGroup();
  }

  static createFormData(enrollment: EnrollmentDetail): any {
    return {
      comments: enrollment.enrollment.comments,
      cost: enrollment.enrollment.cost,
      invoiceStatus: enrollment.enrollment.invoiceStatus || 0
    }
  }

  override writeValue(value: any) {
    if (value) {
      super.writeValue(value);
      if (value.invoiceStatus) {
        this.referenceDataService.getInvoiceStatus(value.invoiceStatus).pipe(first())
          .subscribe(invoiceStatus => this.invoiceStatusReadOnly = invoiceStatus.label);
      }
    }
    else {
      this.formGroup.reset();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["enrollmentVia"]?.currentValue && this.enrollmentVia === ENROLLMENT_VIA.CEVORA) {
      this.formGroup.get("cost").setValue(0);
    }
  }

  validate(_: FormGroup) {
    return this.formGroup.valid ? null : { commentsForm: { valid: false } };
  }

  private createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      comments: [],
      cost: [null, [Validators.required, Validators.min(0), Validators.max(9999)]],
      invoiceStatus: [0]
    });

    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe((newValue) => {
        this.onChange(newValue);
        this.onTouched();
      })
    );
  }

  getMessageKey(mailType: MailType): string {
    if (mailType === MailType.EligibleForExtraTrainingBudgetUnder26) {
      return "communications.eligibleForExtraTrainingBudgetUnder26";
    }
    else if (mailType === MailType.EligibleForExtraTrainingBudgetOver50) {
      return "communications.eligibleForExtraTrainingBudgetOver50";
    }
    return "";
  }

  override setFocus(): void {
    setTimeout(() => this.focusElement?.nativeElement?.focus());
  }
}
