<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>
<div *ngIf="reimbursementRequest">
  <alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                       [linkedEntity]='reimbursementRequestId'
                                       (hasChanges)='notesHasChanges = $event'
                                       (countUpdated)='loadNotesCount()'
                                       [type]='AllowanceApplicationNote'>
  </alimento-ipv-frontend-notes-sidebar>

  <alimento-ipv-frontend-detail-page-layout
    [breadcrumb]='breadcrumb'
    fallbackUrl='/reimbursement-requests'
    header="{{ 'reimbursementRequests.request' | translate }}"
  >
    <ng-container status>
      <alimento-ipv-frontend-reimbursement-status-chip *ngIf='reimbursementRequestId'
                                                       [status]='$any(reimbursementRequest?.statusId)'>
      </alimento-ipv-frontend-reimbursement-status-chip>
    </ng-container>
    <ng-container top-action>
      <div class="buttons" *ngIf='!readOnly'>
        <p-button
          label="{{ 'reimbursementRequests.actions.save' | translate }}"
          severity='secondary'
          styleClass='w-full'
          icon="font-material before:content-['save']"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveReimbursementRequest()"
          [loading]="saving"
        ></p-button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)="setActiveTabIndex($event)"
        [activeTabIndex]="activeTabIndex"
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div [hidden]="activeTabIndex !== 0">
        <alimento-ipv-frontend-reimbursement-request
          [branch]='branch'
          [reimbursementRequest]='reimbursementRequest'
          (changes)='hasChanges = true'
        >
        </alimento-ipv-frontend-reimbursement-request>
      </div>

      <div *ngIf="activeTabIndex === 1">
        <alimento-ipv-frontend-header title="{{ 'reimbursementRequests.trainingTitle' | translate }}">
          <div>
            <p-button
              *ngIf="!this.readOnly"
              [routerLink]='["/reimbursement-requests", reimbursementRequestId, "training", "create"]'
              icon="pi pi-plus"
              label="{{ 'reimbursementRequests.actions.createTraining' | translate  }}"
            ></p-button>
          </div>
        </alimento-ipv-frontend-header>

        <lib-search [data$]='searchTrainingData$'
                    [filters]='searchTrainingFilters'
                    [cardTemplate]='cardTemplate'
                    [inlineFilters]='true'
                    [activeFiltersOnTop]='false'
                    [showSearchFilter]='false'
                    name='reimbursement-request-trainings'
                    searchPlaceholderKey='trainings.searchbarPlaceholder'
                    availableResultsTranslateKey='trainings.nrOfTrainings'
        >
          <ng-template #cardTemplate let-item="item">
            <alimento-ipv-frontend-training-card
              [training]='item'
              tabindex="0"
            >
            </alimento-ipv-frontend-training-card>
          </ng-template>
        </lib-search>
      </div>
    </ng-container>

    <ng-container metadata>

    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
