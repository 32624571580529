<p-tabs *ngIf='enrollmentRequests.length > 0' [(value)]='activeIndex'>
  <p-tablist>
    <p-tab value='0'>
      {{"enrollments.enrollmentRequests" | translate}} ({{enrollmentRequests.length}})
    </p-tab>
    <p-tab value='1'>
      {{ 'enrollments.enrollments' | translate }} ({{enrollments.length}})
    </p-tab>
  </p-tablist>
  <p-tabpanels>
    <p-tabpanel value='0'>

      <div class='flex w-full gap-4 px-5 py-3 text-lg'>
        <div class='w-4 h-4'></div>
        <div class='flex-1'>
          <span class='font-bold'>{{ 'enrollments.name' | translate }}</span>
        </div>
        <div class='flex-1'>
          <span class='font-bold'>{{ 'enrollments.company' | translate }}</span>
        </div>
        <div class='basis-[240px]'>
          <span class='font-bold'>{{ 'enrollments.statut' | translate }}</span>
        </div>
        <div class='basis-[150px]' isEllipsis pTooltip>
          <span class='font-bold'>{{ 'enrollments.date' | translate }}</span>
        </div>
        <div class='basis-[40px]'>
        </div>
      </div>

      <p-accordion [multiple]="true" [(value)]='openEnrollmentRequests' [dt]='detailAccordion'>
        <p-accordion-panel [value]='groupedEnrollments.key' *ngFor="let groupedEnrollments of enrollmentRequestsMap | keyvalue: orderRequestsByStatus">
          <p-accordion-header>
            {{ 'states.' + groupedEnrollments.key | translate }} ({{ groupedEnrollments.value.length }})
          </p-accordion-header>
          <p-accordion-content>
            <div *ngFor="let enrollmentRequest of groupedEnrollments.value">
              <alimento-ipv-frontend-enrollment-request-card
                (actionClicked)="enrollmentRequestAction.emit($event)"
                [enrollmentRequest]="$any(enrollmentRequest)"
                [validationRequest]='validationRequest'
                [training]='training'
                [readOnly]='readOnly'
              ></alimento-ipv-frontend-enrollment-request-card>
            </div>
          </p-accordion-content>
        </p-accordion-panel>
      </p-accordion>
    </p-tabpanel>
    <p-tabpanel value='1'>
      <ng-container *ngTemplateOutlet='enrollmentsTemplate'></ng-container>
    </p-tabpanel>
  </p-tabpanels>
</p-tabs>

<div *ngIf='enrollmentRequests.length === 0'>
  <ng-container *ngTemplateOutlet='enrollmentsTemplate'></ng-container>
</div>

<ng-template #enrollmentsTemplate>
  <p-accordion [multiple]="true" [(value)]='openEnrollments' [dt]='detailAccordion'>
    <p-accordion-panel *ngFor="let groupedEnrollments of enrollmentsMap | keyvalue: orderByStatus" [value]='groupedEnrollments.key'>
      <p-accordion-header>
        {{ 'states.' + groupedEnrollments.key | translate }} ({{ getGroupedEnrollmentCount(groupedEnrollments.value) }})
      </p-accordion-header>
      <p-accordion-content>
        <div *ngFor='let branchEnrollment of groupedEnrollments.value | keyvalue: orderByBranchName'>
          <span class='block font-bold ml-12 pl-1 pt-4 text-lg' *ngIf='branchEnrollment.key'>{{branchEnrollment.key}}</span>
          <span class='block font-bold ml-12 pl-1 pt-4 text-lg' *ngIf='!branchEnrollment.key'>{{'enrollments.branch.noBranch' | translate}}</span>
          <div *ngFor="let enrollment of branchEnrollment.value">
            <alimento-ipv-frontend-enrollment-card
              (actionClicked)="enrollmentAction.emit($event)"
              [enrollment]="$any(enrollment)"
              [training]='training'
              [readOnly]='readOnly'
              [isOpenExternOrCustom]='isOpenExternOrCustom'
              [activeEnrollmentId]='activeEnrollmentId'
            ></alimento-ipv-frontend-enrollment-card>
          </div>
        </div>
      </p-accordion-content>
    </p-accordion-panel>
  </p-accordion>
</ng-template>
