import { Component, effect, OnDestroy, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import {
  AuthorizationService,
  CanComponentDeactivate,
  DateMapper,
  ExtraMenuItem,
  FEATURE,
  FeatureFlagService,
  FilterType,
  HistoryItem,
  IFilter,
  InfiniteScrollDataAdapter,
  LeaveConfirmService,
  MAX_FETCH_COUNT,
  MyMessageService,
  NavigationService,
  NOTES_TYPE, NoteService,
  NotesSidebarComponent,
  PaginatedResponse,
  Role,
  SearchComponent,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TabMenuItem,
  TitleService,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { first, forkJoin, map, Observable, of, Subscription, switchMap, takeWhile, tap, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Branch } from '../../../types/branch.type';
import { BranchService } from '../../../services/branch.service';
import { EmploymentSearchItem, TrainingProgramSearchItem } from '../../../types/searches.type';
import { SearchesService } from '../../../services/searches.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { EmploymentPopupComponent } from '../../../persons/components/employment-popup/employment-popup.component';
import { Employment, EmploymentAction, EmploymentUpdateEvent, StopEmploymentEvent } from '../../../types/person.type';
import { EMPLOYMENT_ITEM_VIEW_MODE, EmploymentActionEnum } from '../../../types/person.enum';
import { EmploymentService } from '../../../services/employment.service';
import {
  StopEmploymentDialogComponent
} from '../../../persons/components/stop-employment-dialog/stop-employment-dialog.component';
import { PersonService } from '../../../services/person.service';
import { BranchComponent } from '../../components/branch/branch.component';
import { EmploymentMapper } from '../../../utils/mapper/employment.mapper';
import { EnterprisesService } from '../../../services/enterprises.service';
import { Enterprise } from '../../../types/enterprise.type';
import { DOCUMENT_SET_TYPE } from '../../../types/reference-data.enum';
import { DocumentService } from '../../../services/document.service';
import { CommunicationsService } from '../../../services/communications.service';
import { MailContextType } from '../../../types/communications.enum';
import { MenuItem } from 'primeng/api';
import { EnterpriseGroup } from '@alimento-ipv-frontend/application-lib';
import { EnterpriseGroupService } from '../../../services/enterprise-group.service';

@Component({
  selector: 'alimento-ipv-frontend-branch-detail',
  templateUrl: './branch-detail.component.html',
  standalone: false
})
export class BranchDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  branch: WritableSignal<Branch> = signal(undefined);
  branchId?: string;
  enterprise: Enterprise;
  enterpriseId: string;
  enterpriseGroup: EnterpriseGroup;
  documentCount: WritableSignal<number> = signal(undefined);
  communicationsCount: WritableSignal<number> = signal(undefined);
  notesCount: WritableSignal<number> = signal(undefined);
  activeEmploymentIndex: WritableSignal<string> = signal('0');

  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  breadcrumb: MenuItem[];
  activeTabIndex = 0;
  savingBranch = false;
  readOnly = false;
  hasChanges: boolean;
  notesHasChanges: boolean;
  showNotes = false;

  searchTrainingProgram$: InfiniteScrollDataAdapter<TrainingProgramSearchItem>;
  trainingProgramFilters: SearchFilter[] = [];
  searchEmployments$: InfiniteScrollDataAdapter<EmploymentSearchItem>;
  searchPreviousEmployments$: InfiniteScrollDataAdapter<EmploymentSearchItem>;
  searchEmploymentsFilters: SearchFilter[] = [];
  searchPreviousEmploymentsFilters: SearchFilter[] = [];
  currentEmploymentAction: EmploymentActionEnum;

  historyItems: HistoryItem[];
  showHistoryItems = false;

  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;

  @ViewChild('searchEmployments')
  searchEmploymentsComponent: SearchComponent;

  @ViewChild('searchPreviousEmployments')
  searchPreviousEmploymentsComponent: SearchComponent;

  @ViewChild(StopEmploymentDialogComponent)
  stopEmploymentDialogComponent: StopEmploymentDialogComponent;

  @ViewChild(BranchComponent)
  branchComponent: BranchComponent;

  BranchNote = NOTES_TYPE.BranchNote;

  protected readonly EMPLOYMENT_ITEM_VIEW_MODE = EMPLOYMENT_ITEM_VIEW_MODE;
  protected readonly FEATURE = FEATURE;

  private _subscriptions: Subscription[] = [];
  private _getCommunicationCountSub: Subscription;

  constructor(
    private translateService: TranslateService,
    private branchService: BranchService,
    private enterpriseService: EnterprisesService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private titleService: TitleService,
    private navigationService: NavigationService,
    private searchesService: SearchesService,
    private referenceDataService: ReferenceDataService,
    private featureService: FeatureFlagService,
    private employmentService: EmploymentService,
    private messageService: MyMessageService,
    private personService: PersonService,
    private communicationService: CommunicationsService,
    private employmentMapper: EmploymentMapper,
    private leaveConfirmationService: LeaveConfirmService,
    private authorizationService: AuthorizationService,
    private documentService: DocumentService,
    private enterpriseGroupService: EnterpriseGroupService,
    private noteService: NoteService
  ) {
    this.featureService.initialize().pipe(first())
      .subscribe(() => {
        this.readOnly = this.featureService.featureOn(FEATURE.BRANCH_AND_ENTERPRISE_MANAGEMENT) &&
          !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
      });

    this._createMenuItems();
    this._createBreadcrumb();

    effect(() => {
      if (this.branch()?.branchId) {
        this.titleService.setTitle(
          [`${this.translateService.instant('titles.branches.detail')} - ${this.branch().branchName}`]);
        this._createEmploymentSearch();
        this._createPreviousEmploymentSearch();
        this._createMenuItems();
        this._createBreadcrumb();
      }
    });
  }

  ngOnInit() {
    this.route.params.pipe(first())
      .subscribe(params => {
        this.branchId = params['branchId'];
        if (this.branchId) {
          this._fetchBranchData();
        }
        else {
          this.route.queryParams.pipe(first())
            .subscribe(queryParams => {
              this.enterpriseId = queryParams['enterpriseId'];
              this.branch.set({
                enterpriseId: queryParams['enterpriseId']
              } as Branch);
              this._fetchEnterpriseData();
            });
        }
      });

    this.route.queryParams.subscribe(
      (queryParams) => (this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0)
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._getCommunicationCountSub?.unsubscribe();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.hasChanges && !this.notesHasChanges) {
      return true;
    }

    const saveActions: Observable<any>[] = [];
    if (this.hasChanges) {
      saveActions.push(this._saveBranch());
    }

    if (this.notesHasChanges) {
      saveActions.push(this.notesSidebarComponent.saveChanges());
    }

    return this.leaveConfirmationService.leaveDialog(() => forkJoin(saveActions));
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem) {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  saveBranch(): void {
    this.savingBranch = true;
    this._saveBranch().subscribe({
      next: () => this.savingBranch = false,
      error: () => this.savingBranch = false
    });
  }

  private _fetchEnterpriseData(): void {
    this.enterpriseService.getEnterprise(this.enterpriseId).pipe(first())
      .subscribe(enterprise => {
        this.enterprise = enterprise;
        this._createMenuItems();
        this._createBreadcrumb();

        if (this.enterprise.enterpriseGroupId) {
          this.enterpriseGroupService.getEnterpriseGroup(this.enterprise.enterpriseGroupId).pipe(first())
            .subscribe(enterpriseGroup => this.enterpriseGroup = enterpriseGroup);
        }
      });
  }

  private _fetchBranchData(): void {
    this.featureService.initialize().subscribe(() => {
      this.branchService.getBranch(this.branchId)
        .pipe(first())
        .subscribe({
          next: branch => {
            this.branch.set(branch);
            this.enterpriseId = branch.enterpriseId;
            this._fetchEnterpriseData();
            this._loadDocuments();
            this.loadNotesCount();
            this._checkForNewCommunication();
            this._createTrainingProgramSearch();
          },
          error: () => this._navigateToErrorPage()
        });
    });
  }

  private _updateBranchInfo(branchId: string): void {
    if (branchId) {
      this.branchService.getBranch(branchId).subscribe((branch: Branch) => {
        this.branch.set(branch);
        if (!this.branchComponent?.formGroup?.value?.branchAlimentoId) {
          this.branchComponent?.formGroup.patchValue({ branchAlimentoId: branch.branchAlimentoId }, { emitEvent: false });
        }
      });
    }
  }

  private _saveBranch(): Observable<{ id: string }> {
    if (!this.branchComponent.isValid()) {
      this.messageService.notAllFieldsValid();

      return new Observable<{ id: string }>((observable) => observable.error());
    }


    const data = this.branchComponent.getData() as Branch;

    const createOrUpdateBranch$ = this.branchId
      ? this.branchService.updateBranch(data)
      : this.branchService.createBranch(data);

    return of(null)
      .pipe(
        tap(() => this.savingBranch = true),
        switchMap(() => createOrUpdateBranch$),
        first(),
        tap((response: { id: string }) => {
          this.branchId = response.id;
          this.messageService.success();
          this.hasChanges = false;
          this._setDetailUrl();

          this._updateBranchInfo(this.branchId);
          this._createMenuItems();
          this._createBreadcrumb();
        })
      );
  }

  private _setDetailUrl(): void {
    if (this.branchId) {
      this.navigationService.replaceState(
        this.router
          .createUrlTree(['/branches', this.branchId], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.tabMenuItems = [
          { name: 'basic', index: 0, title: this.translateService.instant('branches.basicDataTitle') },
          // { name: 'contact', index: 1, title: this.translateService.instant('branches.contactTitle'), disabled: true },
          {
            name: 'employee',
            index: 2,
            title: this.translateService.instant('branches.employeeTitle'),
            disabled: !this.branchId
          },
          // { name: 'training', index: 3, title: this.translateService.instant('branches.trainingTitle'), disabled: true },
          {
            name: 'trainingPlansTitle',
            index: 4,
            title: this.translateService.instant('branches.trainingPlansTitle'),
            disabled: !this.branchId || !this.enterprise?.isFood
          },
          {
            name: 'trainingProgram',
            disabled: !this.branchId,
            index: 5,
            title: this.translateService.instant('trainingPrograms.trainingPrograms')
          },
          {
            index: 6,
            name: 'communications',
            title: this.translateService.instant('trainings.communications.communications'),
            count: this.communicationsCount,
            disabled: !this.branchId
          }
        ];

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('branches.notesTitle'),
            count: this.notesCount,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          },
          {
            name: 'documents',
            title: this.translateService.instant('documents'),
            disabled: !this.branch()?.branchId || !this.branch()?.documentSetUrl,
            count: this.documentCount,
            command: () => {
              window.open(this.branch()?.documentSetUrl, '_blank');
            }
          }
          // { name: 'history', title: this.translateService.instant('branches.historyTitle') },
        ];
      })
    );
  }

  private _createBreadcrumb(): void {
    this.breadcrumb = [
      {
        label: this.translateService.instant('titles.branches.list'),
        routerLink: '/branches'
      },
      {
        label: this.branch()?.branchId ? this.branch().branchAlimentoId + ' - ' + this.branch().branchName :
          this.translateService.instant('branches.newBranch')
      }
    ];
  }

  private _loadDocuments(): void {
    if (this.branch()?.documentSetUrl) {
      this.documentService.getDocumentCount(this.branchId, DOCUMENT_SET_TYPE.BRANCH).pipe(first())
        .subscribe(count => {
          this.documentCount.set(count.count);
          if (count.count === -1) {
            this.documentService.createDocumentSet(this.branchId, DOCUMENT_SET_TYPE.BRANCH).pipe(first())
              .subscribe(documentSet => {
                this.branch.update(branch => {
                  branch.documentSetUrl = documentSet.url;
                  return branch;
                });
                this.documentCount.set(0);
              });
          }
        });
    }
  }

  private _checkForNewCommunication(): void {
    this._getCommunicationCountSub?.unsubscribe();
    const fetching = signal(0);
    this._getCommunicationCountSub = timer(0, 3000)
      .pipe(
        takeWhile(() => fetching() < MAX_FETCH_COUNT),
        tap(() => {
          this._loadCommunicationsCount(fetching);
          fetching.set(fetching() + 1);
        })
      )
      .subscribe();
  }

  private _loadCommunicationsCount(fetching: WritableSignal<number>): void {
    if (this.branch()) {
      const searchRequest: SearchRequest = { filters: [] };
      searchRequest.filters.push({ type: FilterType.filterContextType, values: [MailContextType.Branch] });
      searchRequest.filters.push({ type: FilterType.filterContextValue, values: [this.branch().branchId] });
      this.communicationService.getCommunicationsCount(searchRequest).pipe(first())
        .subscribe(count => {
          const currentCount = this.communicationsCount();
          if (fetching && currentCount !== undefined && count > currentCount) {
            this.messageService.success('trainings.communicationSent');
          }
          fetching.set(MAX_FETCH_COUNT);
          this.communicationsCount.set(count);
        });
    }
  }

  loadNotesCount(): void {
    this.noteService.getNotesCount(this.branchId, NOTES_TYPE.BranchNote).pipe(first())
      .subscribe(count => {
        this.notesCount.set(count);
      });
  }

  private _createTrainingProgramSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterBranch,
      values: [this.branchId]
    });

    this.searchTrainingProgram$ = new InfiniteScrollDataAdapter<TrainingProgramSearchItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<TrainingProgramSearchItem>> => {
      return this.searchesService.searchTrainingPrograms(searchRequest);
    }, searchRequest, true);

    this.trainingProgramFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.rubric',
        key: FilterType.filterRubric,
        data: this.referenceDataService.getTrainingRubrics()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterRubric,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.date,
        label: this.translateService.instant('trainingPrograms.period'),
        key: 'date'
      }
    ];
  }

  private _createEmploymentSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      sortField: 'LastName',
      sortOrder: 1,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterBranch,
      values: [this.branchId]
    });
    searchRequest.filters.push({
      type: FilterType.isActiveEmployment,
      values: [true]
    });

    this.searchEmployments$ = new InfiniteScrollDataAdapter<EmploymentSearchItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<EmploymentSearchItem>> => {
      return this.searchesService.searchEmployments(searchRequest);
    }, searchRequest, true);

    this.searchEmploymentsFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      },
      {
        type: SearchFilterType.multiselect,
        key: FilterType.filterWorkStatus,
        label: 'persons.employments.workStatus',
        data: this.referenceDataService.getWorkStatuses()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterWorkStatus,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        key: FilterType.employmentRole,
        label: 'persons.employments.role',
        data: this.employmentMapper.getEmploymentRoleFilter()
      }
    ];
  }

  private _createPreviousEmploymentSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      sortField: 'LastName',
      sortOrder: 1,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterBranch,
      values: [this.branchId]
    });
    searchRequest.filters.push({
      type: FilterType.isActiveEmployment,
      values: [false]
    });

    this.searchPreviousEmployments$ = new InfiniteScrollDataAdapter<EmploymentSearchItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<EmploymentSearchItem>> => {
      return this.searchesService.searchEmployments(searchRequest);
    }, searchRequest, true);

    this.searchPreviousEmploymentsFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      },
      {
        type: SearchFilterType.multiselect,
        key: FilterType.filterWorkStatus,
        label: 'persons.employments.workStatus',
        data: this.referenceDataService.getWorkStatuses()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterWorkStatus,
              label: type.label,
              value: type.data
            }) as IFilter)))
      }
    ];
  }

  addEmployee(employmentPopupComponent: EmploymentPopupComponent): void {
    this.currentEmploymentAction = EmploymentActionEnum.create;
    employmentPopupComponent.openPopup({ branchId: this.branchId } as Employment);
  }

  onEmploymentPopupSubmit(event: EmploymentUpdateEvent, employmentPopupComponent: EmploymentPopupComponent): void {
    const employment: Employment = event.employment;

    event.setLoading(true);

    let personId$: Observable<string>;
    if (!event.employment.personId && event.person) {
      personId$ = this.personService.createPerson(event.person).pipe(first(), map(result => result.id));
    }
    else {
      personId$ = new Observable(subscriber => subscriber.next(event.employment.personId));
    }

    personId$.pipe(switchMap((personId: string) =>
      event.employmentId
        ? this.employmentService.updateEmployment(personId, event.employmentId, employment)
        : this.employmentService.createEmployment(personId, employment)
    ))
      .subscribe({
        next: () => {
          event.setLoading(false);

          employmentPopupComponent.employmentForm.reset();
          employmentPopupComponent.closePopup();

          this.messageService.success('persons.employments.saved');
          this.searchEmploymentsComponent?.refresh();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  employmentAction(employmentAction: EmploymentAction, employmentPopupComponent: EmploymentPopupComponent) {
    this.currentEmploymentAction = employmentAction.action;
    if (employmentAction.action === EmploymentActionEnum.edit || employmentAction.action === EmploymentActionEnum.view) {
      employmentPopupComponent.openPopup(employmentAction.employment);
    }
    else if (employmentAction.action === EmploymentActionEnum.stopEmployment) {
      this.stopEmploymentDialogComponent.open(employmentAction.employment);
    }
  }

  stopEmployment(stopEmploymentEvent: StopEmploymentEvent) {
    if (this.stopEmploymentDialogComponent.employment?.employmentId) {
      stopEmploymentEvent.setLoading(true);
      this.employmentService
        .stopEmployment(
          this.stopEmploymentDialogComponent.employment?.personId,
          this.stopEmploymentDialogComponent.employment?.employmentId,
          DateMapper.getDateFromDateTimeAsString(stopEmploymentEvent.stopEmploymentAt)
        )
        .pipe(first())
        .subscribe({
          next: () => {
            stopEmploymentEvent.setLoading(false);
            this.stopEmploymentDialogComponent.closeDialog();

            this.messageService.success('persons.employments.employmentStopped');

            this.searchEmploymentsComponent?.refresh();
            this.searchPreviousEmploymentsComponent?.refresh();
          },
          error: () => stopEmploymentEvent.setLoading(false)
        });
    }
  }

  private _navigateToErrorPage(): void {
    this.router.navigate(['error'], {
      state: {
        message: this.translateService.instant('error.itemWithIdDoesNotExist', {
          item: this.translateService.instant('branches.branch'),
          id: this.branchId
        }),
        redirectUrl: '/'
      }
    });
  }

  showTrainingPlansHistoryItems(items: HistoryItem[]): void {
    this.historyItems = items;
    this.showHistoryItems = true;
  }
}
