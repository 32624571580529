import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SalesEntryEvent, SearchSalesInvoice } from '../../../types/training.type';
import { SALES_INVOICE_ACTION } from '../../../types/training.enum';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'alimento-ipv-frontend-sales-invoice-action-dialog',
    templateUrl: './sales-invoice-action-dialog.component.html',
    standalone: false
})
export class SalesInvoiceActionDialogComponent {
  @Input()
  salesInvoice: SearchSalesInvoice;

  @Output()
  onSubmit = new EventEmitter<SalesEntryEvent>();

  dialogVisible = false;
  loading = false;
  showFormGroup = false;
  formGroup: FormGroup;
  private action: SALES_INVOICE_ACTION;
  private _titleMap: Map<any, any>;

  constructor(private formBuilder: FormBuilder) {
    this._createTitleMap();
  }

  get titleObject(): { title: string, info: string, complete: string } {
    return this._titleMap.get(this.action);
  }

  open(action: SALES_INVOICE_ACTION): void {
    this.action = action;
    this.showFormGroup = [SALES_INVOICE_ACTION.reject, SALES_INVOICE_ACTION.reApprove].includes(action);
    this._createFormGroup();
    this.dialogVisible = true;
    this.loading = false;
  }

  submit(): void {
    this.onSubmit.emit({
      salesInvoice: this.salesInvoice,
      data: this.showFormGroup ? JSON.parse(JSON.stringify(this.formGroup.value)) : undefined,
      action: this.action,
      setLoading: state => this.loading = state
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
  }

  private _createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      comments: [null]
    });
  }

  private _createTitleMap(): void {
    this._titleMap = new Map();
    this._titleMap.set(SALES_INVOICE_ACTION.approve, {
      title: 'trainings.salesInvoices.approveTitle',
      info: 'trainings.salesInvoices.approveInfo',
      complete: 'trainings.salesInvoices.approve'
    });
    this._titleMap.set(SALES_INVOICE_ACTION.reject, {
      title: 'trainings.salesInvoices.rejectTitle',
      info: '',
      complete: 'trainings.salesInvoices.reject'
    });
  }
}
