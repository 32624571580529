<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ getTitle() | translate }}"
>
  <div class="grid grid-cols-2 gap-2 bg-grey-0 p-4 mb-4" *ngIf="reimbursement">
    <alimento-ipv-frontend-metadata-item
      [cardLayout]='true'
      header='{{"reimbursements.type" | translate}}'
      [value]='type'
    ></alimento-ipv-frontend-metadata-item>

    <alimento-ipv-frontend-metadata-item
      [cardLayout]='true'
      header='{{"reimbursements.amount" | translate}}'
      [value]='reimbursement.amount | currency:"EUR"'
    ></alimento-ipv-frontend-metadata-item>

    <div class='col-span-2' *ngIf='reimbursement.remarks'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"reimbursements.internalRemarks" | translate}}'
        [value]='reimbursement.remarks'
      ></alimento-ipv-frontend-metadata-item>
    </div>

    <div class='col-span-2' *ngIf='reimbursement.externalRemarks'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"reimbursements.externalRemarks" | translate}}'
        [value]='reimbursement.externalRemarks'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div [formGroup]="formGroup" class='mb-2'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      formField='comments'
                                      fieldKey='enterprises.accountNumbers.comments'>
      <textarea
        #focusElement
        class="w-full"
        formControlName="comments"
        id="comments"
        pTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>
  </div>

  <div *ngIf="contactPersonsWithNoEmail.length === 1 && selectedContactPersons.length > 1"
       class='my-4 p-4 border-l-4 border-l-warning bg-warning-bg text-warning flex flex-col'>
    <span>{{'reimbursements.contactPersonWithoutEmail' | translate:{
      contactPerson: contactPersonsWithNoEmail[0].lastName + " " + contactPersonsWithNoEmail[0].firstName
    } }}</span>
  </div>

  <div *ngIf='contactPersonsWithNoEmail.length > 1 || (contactPersonsWithNoEmail.length === 1 && selectedContactPersons.length === 1)'
       class='my-4 p-4 border-l-4 border-l-danger bg-danger-bg text-danger flex flex-col'>
    <span>{{'reimbursements.noContactPersonsHaveEmail' | translate}}</span>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        [disabled]='contactPersonsWithNoEmail.length > 1'
        (onClick)="submit()"
        [loading]='loading'
        label="{{ getTitle() | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'enterprises.accountNumbers.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
