<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>
<ng-container *ngIf='person()'>
  <alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                       [linkedEntity]='person().personId'
                                       [type]='PersonNote'
                                       [readonly]='readOnly'
                                       (countUpdated)='loadNotesCount()'
                                       (hasChanges)='notesHasChanges = $event'>
  </alimento-ipv-frontend-notes-sidebar>
</ng-container>

<form *ngIf="isNewPerson || person()">
  <alimento-ipv-frontend-detail-page-layout
    [breadcrumb]='breadcrumb'
    fallbackUrl='/persons'
    header="{{ 'persons.person' | translate }}"
  >
    <ng-container top-action>
      <div class="buttons" *ngIf='!readOnly'>
        <p-button
          label="{{ 'persons.actions.save' | translate }}"
          severity='secondary'
          styleClass='w-full'
          icon="font-material before:content-['save']"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="savePerson()"
          [loading]="savingPerson"
          [disabled]="!personComponent?.formGroup?.dirty"
        ></p-button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)="setActiveTabIndex($event)"
        [activeTabIndex]="activeTabIndex"
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div [hidden]="activeTabIndex !== 0" >
        <alimento-ipv-frontend-header title="{{ 'persons.personDataTitle' | translate }}">
        </alimento-ipv-frontend-header>

        <alimento-ipv-frontend-person
          [person]="person()"
          [showPersonalContactData]="true"
          [dataOptional]="true"
          [readOnly]='readOnly'
          (personFormChanges)="onPersonFormChanges($event)"
        ></alimento-ipv-frontend-person>
      </div>

      <div [hidden]="activeTabIndex !== 1">
        <alimento-ipv-frontend-employments [person]="person()" [readOnly]='readOnly'></alimento-ipv-frontend-employments>
      </div>

      <div [hidden]='activeTabIndex !== 2'>
        <alimento-ipv-frontend-header title="{{ 'persons.enrollmentsTitle' | translate }}">
        </alimento-ipv-frontend-header>
        <!--        <alimento-ipv-frontend-enrollment-popup #enrollmentPopupComponent-->
        <!--                                                [training]='enrollmentTraining'-->
        <!--                                                [readOnly]='true'-->
        <!--                                                (formSubmit)='enrollmentPopupComponent.closePopup()'-->
        <!--        ></alimento-ipv-frontend-enrollment-popup>-->

        <lib-search [data$]='searchEnrollmentData$'
                    [filters]='searchEnrollmentFilters'
                    [cardTemplate]='enrollmentTemplate'
                    [inlineFilters]='true'
                    [showSearchFilter]='false'
                    [activeFiltersOnTop]='true'
                    [activeFilters]='activeEnrollmentFilters'
                    [saveFilters]='false'
                    name='person-enrollment-list'
                    searchPlaceholderKey='persons.enrollmentSearchbarPlaceholder'
                    availableResultsTranslateKey='enrollments.enrollments'
        >
          <ng-template #enrollmentTemplate let-enrollment="item">
            <alimento-ipv-frontend-training-enrollment-card #card
                                                            [enrollment]='enrollment'
                                                            [showName]='false'
                                                            (click)='card.navigateToTraining($event)'
            >
            </alimento-ipv-frontend-training-enrollment-card>
          </ng-template>
        </lib-search>
      </div>

      <div [hidden]='activeTabIndex !== TRANSITION_INDEX'>
        <alimento-ipv-frontend-transitions [person]='person()'
                                           [activeTransitionId]='activeTransitionId'
                                           [readOnly]='readOnly'
        ></alimento-ipv-frontend-transitions>
      </div>

      <div [hidden]="activeTabIndex !== 4" *ngIf='activeTabIndex === 4'>
        <alimento-ipv-frontend-header title="{{ 'persons.paidEducationalLeavesAttestsTitle' | translate }}">
        </alimento-ipv-frontend-header>

        <lib-search [activeFiltersOnTop]='false'
                    [cardTemplate]='attestCardTemplate'
                    [data$]='searchAttests$'
                    [filters]='searchAttestsFilters'
                    [inlineFilters]='true'
                    [showSearchFilter]='false'
                    searchPlaceholderKey='paidEducationalLeaves.searchPlaceholder'
                    availableResultsTranslateKey='paidEducationalLeaves.certificates'
                    name='person-attests-search'
        >
          <ng-template #attestCardTemplate let-item="item" let-selected="selected">
            <alimento-ipv-frontend-paid-educational-leave-card
              [paidEducationalLeaveAttest]='item'
              [selected]='selected'
              [clickable]='true'
              [showPersonName]='false'
              tabindex="0"
            ></alimento-ipv-frontend-paid-educational-leave-card>
          </ng-template>
        </lib-search>
      </div>

      <div [hidden]="activeTabIndex !== 5">
        <alimento-ipv-frontend-header title="{{ 'persons.historyTitle' | translate }}"> </alimento-ipv-frontend-header>
      </div>
    </ng-container>

    <ng-container metadata *ngIf="person">
      <ng-container *ngIf="person()?.createdOn">
        <alimento-ipv-frontend-metadata-item
          header="{{ 'persons.createdOn' | translate }}"
          value="{{ person().createdOn | date : 'dd/MM/yyyy' }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="person()?.editedOn">
        <alimento-ipv-frontend-metadata-item
          header="{{ 'persons.lastEdited' | translate }}"
          value="{{ person().editedBy }}
          {{ 'persons.editedOn' | translate : { on: person().editedOn | date : 'dd/MM/yyyy' } }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</form>
