<div class='py-4'>
  <div class='flex items-center gap-4 justify-between w-full ml-2 mb-2'>
    <div class='flex items-center gap-x-2'>
      <span>{{salesInvoice.enrollment?.lastName}} {{salesInvoice.enrollment?.firstName}}</span>
      <span>-</span>
      <span>{{statute}}</span>
    </div>
    <div class='flex items-center gap-x-2'>
      <span>{{salesInvoice.client.branch?.name}}</span>
      <span>-</span>
      <span>{{salesInvoice.client.branch?.address}}</span>
    </div>
  </div>
  <div class="hover:bg-primary hover:bg-opacity-[7%] w-full mx-2 cursor-pointer" (click)="showExtraInfo = !showExtraInfo">
    <div class='flex items-center gap-2'>
      <div
        (keyup.enter)="showExtraInfo = !showExtraInfo"
        class="flex items-center p-2 m-2 text-primary outline-primary"
        tabindex="0"
      >
        <i class="pi pi-angle-{{ showExtraInfo ? 'up' : 'down' }}"></i>
      </div>

      <div class='flex items-center justify-between w-full'>
        <div class='flex items-center justify-between gap-4 w-80' [ngClass]='{"line-through": salesInvoice.stateId === SALES_INVOICE_STATUS.REJECTED}'>
          <span>{{'trainings.salesInvoices.invoice' | translate}}</span>
          <span class='font-bold'>{{(salesInvoice.amount || 0) | currency:"EUR"}}</span>
        </div>

        <div class='flex items-center gap-2 mr-2'>
          <span *ngIf='salesInvoice.stateId === SALES_INVOICE_STATUS.APPROVED'>
            {{'trainings.salesInvoices.approvedOn' | translate:{date: salesInvoice.stateTransitionDate | date: 'dd/MM/yyyy'} }}
          </span>
          <span *ngIf='salesInvoice.stateId === SALES_INVOICE_STATUS.REJECTED'>
            {{'trainings.salesInvoices.rejectedOn' | translate:{date: salesInvoice.stateTransitionDate | date: 'dd/MM/yyyy'} }}
          </span>
          <p-button *ngIf='canApprove'
                    (click)='sendAction(SALES_INVOICE_ACTION.approve, $event)'>
            {{"trainings.salesInvoices.approve" | translate}}
          </p-button>

          <p-button *ngIf="canEdit"
                    (onClick)="sendAction(SALES_INVOICE_ACTION.edit, $event)"
                    tabindex="0"
                    variant='text'
                    styleClass='inverted w-8 h-8'
                    icon="m-edit"
          ></p-button>

          <alimento-ipv-frontend-more-actions *ngIf='moreActions?.length > 0' [actions]='moreActions'></alimento-ipv-frontend-more-actions>
        </div>
      </div>
    </div>

    <div *ngIf="showExtraInfo" class="grid grid-cols-2 bg-grey-0 p-2 pl-12">

      <div class='flex items-center justify-between gap-4 w-80 ml-2'>
        <span>{{'trainings.salesInvoices.trainingCost' | translate}}</span>
        <span>{{salesInvoice.cost | currency:"EUR"}}</span>
      </div>

      <div>
        <alimento-ipv-frontend-metadata-item *ngIf="!salesInvoice.rejectComment"
                                             [header]='"trainings.salesInvoices.notes" | translate'
                                             [value]='salesInvoice.notes'
        ></alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item *ngIf="salesInvoice.rejectComment"
                                             [header]='"trainings.salesInvoices.rejectComment" | translate'
                                             [value]='salesInvoice.rejectComment'
        ></alimento-ipv-frontend-metadata-item>
      </div>
    </div>
  </div>
</div>

