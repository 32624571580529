import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SearchSalesInvoice } from '../../../types/training.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { first } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { MenuItemUtilsService } from '@alimento-ipv-frontend/ui-lib';
import { SALES_INVOICE_ACTION, SALES_INVOICE_STATUS } from '../../../types/training.enum';

@Component({
    selector: 'alimento-ipv-frontend-sales-invoice-card',
    templateUrl: './sales-invoice-card.component.html',
    standalone: false
})
export class SalesInvoiceCardComponent implements OnChanges {
  @Input()
  salesInvoice!: SearchSalesInvoice;

  @Input()
  readOnly = false;

  @Output()
  actionClicked = new EventEmitter<SALES_INVOICE_ACTION>();

  status: string;
  statute: string;
  canApprove: boolean;
  canEdit: boolean;
  showExtraInfo = false;
  moreActions: MenuItem[] = [];

  protected readonly SALES_INVOICE_ACTION = SALES_INVOICE_ACTION;
  protected readonly SALES_INVOICE_STATUS = SALES_INVOICE_STATUS;

  constructor(private referenceDataService: ReferenceDataService,
              private menuItemUtilsService: MenuItemUtilsService,) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['salesInvoice']?.currentValue) {
      if (this.salesInvoice.enrollment?.workStatusId) {
        this.referenceDataService.getWorkStatus(this.salesInvoice.enrollment?.workStatusId).pipe(first())
          .subscribe(statute => this.statute = statute.label);
      }
      this.canEdit = !this.readOnly && this.salesInvoice.stateId === SALES_INVOICE_STATUS.DRAFT;
      this.canApprove = this.canEdit && this.salesInvoice.stateId === SALES_INVOICE_STATUS.DRAFT;

      this.moreActions = this._getMenuItems();
    }
  }

  sendAction(action: SALES_INVOICE_ACTION, event?: Event): void {
    this.actionClicked.emit(action);
    event?.stopImmediatePropagation();
  }

  private _getMenuItems(): MenuItem[] {
    const view = this.menuItemUtilsService.getMenuItem('trainings.salesInvoices.view', () => this.sendAction(SALES_INVOICE_ACTION.view));

    if (this.readOnly) {
      return [view];
    }
    else if (this.salesInvoice.stateId === SALES_INVOICE_STATUS.DRAFT) {
      return [
        view,
        this.menuItemUtilsService.getMenuItem('trainings.salesInvoices.edit', () => this.sendAction(SALES_INVOICE_ACTION.edit)),
        this.menuItemUtilsService.getMenuItem('trainings.salesInvoices.approve', () => this.sendAction(SALES_INVOICE_ACTION.approve)),
        this.menuItemUtilsService.getMenuItem('trainings.salesInvoices.reject', () => this.sendAction(SALES_INVOICE_ACTION.reject))
      ];
    }
    else if (this.salesInvoice.stateId === SALES_INVOICE_STATUS.REJECTED) {
      return [
        view,
        // this.menuItemUtilsService.getMenuItem('trainings.salesInvoices.reApprove', () => this.sendAction(SALES_INVOICE_ACTION.reApprove))
      ]
    }

    return [view];
  }
}
