<p-dialog
  *ngIf='dialogVisible'
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ titleObject.title | translate }}"
>
  <div class="flex bg-grey-0 p-4 mb-4" *ngIf="salesInvoice">
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"trainings.salesInvoices.attendee" | translate}}'
        value='{{salesInvoice.enrollment?.firstName}} {{salesInvoice.enrollment?.lastName}}'
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"trainings.salesInvoices.amount" | translate}}'
        [value]='salesInvoice.amount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div *ngIf='titleObject.info' class='mb-2'>
    <span>{{titleObject.info | translate}}</span>
  </div>

  <div [formGroup]="formGroup" class='mb-2' *ngIf='showFormGroup'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      formField='comments'
                                      fieldKey='enterprises.accountNumbers.comments'>
      <textarea
        class="w-full"
        formControlName="comments"
        id="comments"
        pTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="submit()"
        [loading]='loading'
        label="{{ titleObject.complete | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.salesInvoices.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
