<div class="items-center gap-2 grid grid-cols-[minmax(0,_1fr)_150px_100px_minmax(0,_1fr)]">
  <div isEllipsis pTooltip>
    <div *ngIf="branch" class='flex itemss-center gap-1'>
      <span>{{ branch.name }}</span>
      <span *ngIf='branch.isActive === false' class='text-danger'>{{ 'branches.inActive' | translate}}</span>
    </div>
    <span *ngIf="!branch" class="font-bold">{{ 'branches.branchName' | translate }}</span>
  </div>

  <div isEllipsis pTooltip>
    <span *ngIf="branch">{{ branch.vatNumber }}</span>
    <span *ngIf="!branch" class="font-bold">{{ 'branches.vatNumber' | translate }}</span>
  </div>

  <div isEllipsis pTooltip>
    <span *ngIf="branch">{{ branch.rszNumber }}</span>
    <span *ngIf="!branch" class="font-bold">{{ 'branches.rszNumber' | translate }}</span>
  </div>

  <div isEllipsis pTooltip>
    <span *ngIf="branch">{{ branch.address }}</span>
    <span *ngIf="!branch" class="font-bold">{{ 'branches.address' | translate }}</span>
  </div>
</div>
