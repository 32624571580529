<alimento-ipv-frontend-overview-page-layout header='trainings.menuTitle'>
  <ng-container top-right>
    <p-menu #menu [model]='addButtonItems' [popup]='true' appendTo='body'></p-menu>
    <p-button (click)="menu.toggle($event)"
              *hideIfAllUnauthorized='[Role.ADMIN, Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE]'
              icon='pi pi-chevron-down'
              iconPos='right'
              label="{{ 'trainings.actions.newFormation' | translate }}"
    ></p-button>
  </ng-container>

  <p-tabs [(value)]='activeIndex'>
    <p-tablist>
      <p-tab value='0'>
        <span class='text-xl p-tabview-title'>{{'trainings.menuTitle' | translate}}</span>
      </p-tab>
      <p-tab value='1'>
        <span class='text-xl p-tabview-title'>{{'trainings.participantsHeader' | translate}}</span>
      </p-tab>
    </p-tablist>

    <p-tabpanels>
      <p-tabpanel value='0'>
        <lib-search [data$]='searchData$'
                    [filters]='searchFilters'
                    [cardTemplate]='cardTemplate'
                    [sortItems]='sortFilters'
                    [expandAll]='false'
                    name='trainings-list'
                    searchPlaceholderKey='trainings.searchbarPlaceholder'
                    availableResultsTranslateKey='trainings.menuTitle'
        >
          <ng-template #cardTemplate let-training="item">
            <alimento-ipv-frontend-training-card [training]='training'>
            </alimento-ipv-frontend-training-card>
          </ng-template>
        </lib-search>
      </p-tabpanel>

      <p-tabpanel value='1'>
        <alimento-ipv-frontend-enrollment-popup #enrollmentPopupComponent
                                                [training]='enrollmentTraining'
                                                [readOnly]='true'
                                                (formSubmit)='enrollmentPopupComponent.closePopup()'
        ></alimento-ipv-frontend-enrollment-popup>

        <lib-search [data$]='searchEnrollmentData$'
                    [filters]='searchEnrollmentFilters'
                    [cardTemplate]='enrollmentTemplate'
                    [sortItems]='sortFilters'
                    [expandAll]='false'
                    name='training-enrollment-list'
                    searchPlaceholderKey='trainings.enrollmentSearchbarPlaceholder'
                    availableResultsTranslateKey='trainings.participantsHeader'
        >
          <ng-template #enrollmentTemplate let-enrollment="item">
            <alimento-ipv-frontend-training-enrollment-card [enrollment]='enrollment'
                                                            (click)='openEnrollmentPopup(enrollment, enrollmentPopupComponent)'
            >
            </alimento-ipv-frontend-training-enrollment-card>
          </ng-template>
        </lib-search>
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>
</alimento-ipv-frontend-overview-page-layout>
