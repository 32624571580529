<div>
  <alimento-ipv-frontend-header title="{{ 'paidEducationalLeaves.contactPersons' | translate }}">
  </alimento-ipv-frontend-header>
  <div class='max-w-[650px]'>
    <div class='mb-8'>
      <div *ngIf='contactPersons?.length > 0'>
        <div *ngFor='let contactPerson of contactPersons' class='flex flex-col mb-2'>
          <span>{{contactPerson.lastName}} {{contactPerson.firstName}}</span>
          <span class='text-sm'>{{'persons.email' | translate}}: {{contactPerson.email}}</span>
          <div class='text-sm flex items-center gap-1'>
            <span>{{'persons.employments.responsibilities' | translate}}:</span>
            <span *ngFor='let responsibility of contactPerson.responsibilities; let last = last'>
                {{responsibility.label}}{{!last ? ', ' : ''}}
              </span>
          </div>
        </div>
      </div>
      <div *ngIf='contactPersons?.length === 0'>
        <alimento-ipv-frontend-paid-educational-leave-contact-dialog #contactDialog
                                                                     [branchId]='branch.branchId'
                                                                     (submitPopup)='updateContactPerson.emit($event);'
        ></alimento-ipv-frontend-paid-educational-leave-contact-dialog>

        <div class='flex flex-col gap-2'>
          <p-button (click)='contactDialog.open()'
                    label="{{'paidEducationalLeaves.selectContactPerson' | translate}}"
                    [link]='true'
                    styleClass='px-0'
                    icon='font-material before:content-["Add"]'
          >
          </p-button>

          <small class='p-error text-red'>
            {{"error.at-least-one-contact-bev-needed" | translate}}
          </small>
        </div>
      </div>
    </div>
  </div>

  <alimento-ipv-frontend-header title="{{ 'paidEducationalLeaves.basicData' | translate }}">
  </alimento-ipv-frontend-header>
  <div class='max-w-[700px]'>
    <h3>{{'trainings.basicData.general' | translate}}</h3>

    <div class='grid grid-cols-3'>
      <alimento-ipv-frontend-form-field [required]="true"
                                        fieldKey='paidEducationalLeaves.startDate'>
        <span>{{paidEducationalLeaveAttest.startDate | date: "dd/MM/yyyy"}}</span>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field [required]="true"
                                        fieldKey='paidEducationalLeaves.submissionDate'>
        <span>{{paidEducationalLeaveAttest.submissionDate | date: "dd/MM/yyyy"}}</span>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field [required]="true"
                                        fieldKey='paidEducationalLeaves.endDate'>
        <span>{{paidEducationalLeaveAttest.endDate | date: "dd/MM/yyyy"}}</span>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field [required]="true"
                                        *ngIf='paidEducationalLeaveAttest?.files?.length > 0'
                                        fieldKey='paidEducationalLeaves.attest'>
        <lib-documents [files]='paidEducationalLeaveAttest.files'
                       [bigIconSize]='true'
                       [canAdd]='false'
        ></lib-documents>
      </alimento-ipv-frontend-form-field>
    </div>

    <div>
      <alimento-ipv-frontend-form-field [required]='true'
                                        *ngIf='branch?.administrativeRules'
                                        fieldKey='branches.administrativeRules'
      >
        <div #content
             class='text-text whitespace-pre-line'
             [ngClass]='{ "h-full overflow-auto": showMoreAdministrativeRules,
            "max-h-[72px] overflow-hidden": !showMoreAdministrativeRules
            }'
             [innerHTML]='branch.administrativeRules'
        ></div>
        <p-button *ngIf='showMoreAdministrativeRules || hasOverflow(content)'
                  [link]='true'
                  styleClass='px-0'
                  label="{{ (showMoreAdministrativeRules ? 'notes.showLess': 'notes.showMore') | translate }}"
                  (click)="toggleShowMoreAdministrativeRules()">
        </p-button>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>
</div>
