<p-dialog
  (blur)="popupVisible = false"
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [closable]="true"
  [styleClass]="'full-screen-dialog'"
  [focusOnShow]='false'
  appendTo="body"
  *ngIf="popupVisible"
>
  <ng-template pTemplate="header">
    <div class="dialog-header-content">
      <span class="p-dialog-title">{{titleObject.title | translate}}</span>
    </div>
  </ng-template>

  <div class="dialog-steps-container">
    <p-stepper [value]="activeIndex" [linear]="true">
      <p-step-list>
        <p-step *ngFor='let stepItem of stepsItems; let i = index' [value]="i">
          <ng-template let-value="value">
            <span *ngIf='activeIndex <= i' class='p-step-number'
                  [ngClass]='{"!border-primary": activeIndex === i}'>
              {{value + 1}}
            </span>
            <span *ngIf='activeIndex > i' class='p-step-number font-material'>check</span>
          </ng-template>
        </p-step>
      </p-step-list>
    </p-stepper>
  </div>

  <form class="dialog-body-content" [formGroup]="formGroup" *ngIf="formGroup">
    <div [hidden]="activeIndex !== 0">
      <alimento-ipv-frontend-enrollment-branch-form
        [training]="training"
        [enrollmentId]='enrollment?.enrollment?.enrollmentId'
        formControlName="branchForm"
        name="branchForm"
      >
      </alimento-ipv-frontend-enrollment-branch-form>
    </div>
    <div [hidden]="activeIndex !== 1">
      <ng-template #personFormTemplate>
        <alimento-ipv-frontend-person
          [dataOptional]="false"
          [showPersonalContactData]="enrollmentVia === ENROLLMENT_VIA.NO_COMPANY"
          [person]="person"
          [readOnly]='!!enrollment?.enrollment?.enrollmentId'
          formControlName="personForm"
          name="personForm"
        ></alimento-ipv-frontend-person>
      </ng-template>
      <div *ngIf='!enrollment?.enrollment?.enrollmentId else personFormTemplate'>
        <p-tabs [(value)]="personFormIndex">
          <p-tablist>
            <p-tab value='0'>
              {{ 'enrollments.searchPerson' | translate }}
            </p-tab>
            <p-tab value='1'>
              {{ 'enrollments.newPerson' | translate }}
            </p-tab>
          </p-tablist>
          <p-tabpanels>
            <p-tabpanel value='0'>
              <div>
                <alimento-ipv-frontend-person-select
                  [formControl]="searchPersonFormControl"
                ></alimento-ipv-frontend-person-select>

                <div [hidden]="!searchPersonFormControl.value" class="selected-person-container">
                  <ng-container *ngTemplateOutlet="personFormTemplate"></ng-container>
                </div>
              </div>
            </p-tabpanel>
            <p-tabpanel value='1'>
              <ng-container *ngTemplateOutlet="personFormTemplate"></ng-container>
            </p-tabpanel>
          </p-tabpanels>
        </p-tabs>
      </div>
    </div>
    <div [hidden]="activeIndex !== 2">
      <alimento-ipv-frontend-extra-form
        formControlName="extraForm"
        name="extraForm"
        [training]='training'
        [branchId]="branchId"
        [person]="currentPerson"
        [enrollment]='enrollment'
      >
      </alimento-ipv-frontend-extra-form>
    </div>
    <div [hidden]="activeIndex !== 3">
      <alimento-ipv-frontend-comments-form
        [enrollmentVia]='enrollmentVia'
        [extraMail]='extraMailResponse'
        formControlName="commentsForm"
        name="commentsForm"
      ></alimento-ipv-frontend-comments-form>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <div class="dialog-footer-content">
      <p-button
        (onClick)="goToPreviousStep()"
        icon="{{ activeIndex === 0 ? '' : 'pi pi-angle-left' }}"
        iconPos="left"
        label="{{ (activeIndex === 0 ? 'trainings.sessions.cancel' : 'enrollments.previous') | translate }}"
        variant='text'
      ></p-button>

      <p-button
        (onClick)="goToNextStep()"
        [loading]="loading"
        icon="pi pi-angle-right"
        iconPos="right"
        label="{{(activeIndex === stepsItems.length - 1 ? titleObject.complete : 'enrollments.next') | translate}}"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
