import { NgModule } from '@angular/core';
import { EnrollmentsOverviewComponent } from './components/enrollments-overview/enrollments-overview.component';
import { EnrollmentPopupComponent } from './components/enrollments-popup/enrollment-popup.component';
import { EnrollmentCardComponent } from './components/enrollment-card/enrollment-card.component';
import { StepsModule } from 'primeng/steps';
import { UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { EnrollmentsComponent } from './components/enrollments/enrollments.component';
import { EnrollmentExtraFormComponent } from './components/enrollment-extra-form/enrollment-extra-form.component';
import {
  EnrollmentCommentsFormComponent
} from './components/enrollment-comments-form/enrollment-comments-form.component';
import {
  CancelEnrollmentDialogComponent
} from './components/cancel-enrollment-dialog/cancel-enrollment-dialog.component';
import { EnrollmentBranchFormComponent } from './components/enrollment-branch-form/enrollment-branch-form.component';
import { BranchesModule } from '../branches/branches.module';
import { PersonsModule } from '../persons/persons.module';
import { UtilsModule } from '../utils/utils.module';
import {
  EnrollmentRequestPopupComponent
} from './components/enrollment-request-popup/enrollment-request-popup.component';
import { EnrollmentRequestCardComponent } from './components/enrollment-request-card/enrollment-request-card.component';
import {
  EnrollmentRequestParticipantCardComponent
} from './components/enrollment-request-participant-card/enrollment-request-participant-card.component';
import {
  EnrollmentRequestParticipantValidationPopupComponent
} from './components/enrollment-request-participant-validation-popup/enrollment-request-participant-validation-popup.component';
import {
  EnrollmentRequestBranchPopupComponent
} from './components/enrollment-request-branch-popup/enrollment-request-branch-popup.component';
import {
  EnrollmentRequestParticipantPopupComponent
} from './components/enrollment-request-participant-popup/enrollment-request-participant-popup.component';
import {
  EnrollmentRequestParticipantPopup2Component
} from './components/enrollment-request-participant-popup2/enrollment-request-participant-popup2.component';

@NgModule({
  imports: [
    UiLibModule,
    StepsModule,
    PersonsModule,
    BranchesModule,
    UtilsModule
  ],
  exports: [
    EnrollmentsComponent,
    EnrollmentRequestParticipantPopupComponent,
    EnrollmentBranchFormComponent,
    EnrollmentExtraFormComponent,
    EnrollmentCommentsFormComponent,
    EnrollmentPopupComponent
  ],
  declarations: [
    EnrollmentsOverviewComponent,
    EnrollmentPopupComponent,
    EnrollmentCardComponent,
    EnrollmentsComponent,
    EnrollmentExtraFormComponent,
    EnrollmentCommentsFormComponent,
    CancelEnrollmentDialogComponent,
    EnrollmentBranchFormComponent,
    EnrollmentRequestPopupComponent,
    EnrollmentRequestCardComponent,
    EnrollmentRequestParticipantCardComponent,
    EnrollmentRequestParticipantValidationPopupComponent,
    EnrollmentRequestParticipantPopupComponent,
    EnrollmentRequestBranchPopupComponent,
    EnrollmentRequestParticipantPopup2Component
  ]
})
export class EnrollmentsModule {
}
