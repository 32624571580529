import {
  Component, effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges, ViewChild,
  WritableSignal
} from '@angular/core';
import { Note, NoteUpdateEvent } from '../../types/ui-lib.type';
import { MessageService } from 'primeng/api';
import { NoteService } from '../../services/note.service';
import { first, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NoteComponent } from '../note/note.component';
import { tap } from 'rxjs/operators';
import { LeaveConfirmService } from '../../services/leave-confirm.service';
import { NOTES_TYPE } from '../../types/ui-lib.enum';

@Component({
    selector: 'alimento-ipv-frontend-notes-sidebar',
    templateUrl: './notes-sidebar.component.html',
    standalone: false
})
export class NotesSidebarComponent implements OnChanges {

  @Input()
  notes: Note[] = [];

  @Input()
  visible = false;

  @Input({required: true})
  linkedEntity: string;

  @Input({required: true})
  type: NOTES_TYPE;

  @Input()
  readonly = false;

  @Output()
  visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  hasChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  countUpdated: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("addNoteComponent")
  addNoteComponent: NoteComponent;

  showAddNote: WritableSignal<boolean> = signal(false);

  isDirty: WritableSignal<string> = signal(undefined);
  private _noteComponent: NoteComponent;

  constructor(private noteService: NoteService,
              private translateService: TranslateService,
              private leaveConfirmService: LeaveConfirmService,
              private messageService: MessageService) {
    effect(() => this.hasChanges.emit(!!this.isDirty() || this.showAddNote()));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible']?.currentValue === true && changes['visible']?.previousValue === false) {
      this._fetchNotes();
    }
  }

  addNote(event: NoteUpdateEvent): void {
    this._executeCreate(event.note)
      .subscribe({
        next: () => event.setLoading(false),
        error: () => event.setLoading(false)
      })
  }

  cancelAddNote(): void {
    this.showAddNote.set(false);
  }

  close(): void {
    if (this.isDirty() || this.showAddNote()) {
      this.leaveConfirmService.leaveDialog(() => this.saveChanges()).then((result) => {
        if (result) {
          this.visible = false;
          this.isDirty.set(undefined);
          this.visibleChange.emit(false);
        }
      });
    }
    else {
      this.visible = false;
      this.isDirty.set(undefined);
      this.visibleChange.emit(false);
    }
  }

  editNote(event: NoteUpdateEvent): void {
    this._executeUpdate(event.note).subscribe({
      next: () => event.setLoading(false),
      error: () => event.setLoading(false)
    });
  }

  removeNote(event: NoteUpdateEvent): void {
    this.noteService.deleteNote(event.note).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('notes.deleted')
          });
          this._fetchNotes();
          this.countUpdated.emit();
          event.setLoading(false);
        },
        error: () => event.setLoading(false)
      });
  }

  cancelNote(): void {
    this.isDirty.set(undefined);
  }

  saveChanges(): Observable<any> {
    let noteComponent;
    if (this.isDirty()) {
      noteComponent = this._noteComponent;
    }
    else if (this.showAddNote()) {
      noteComponent = this.addNoteComponent;
    }

    if (!noteComponent || !noteComponent.isValid) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors'),
      });
      return new Observable<any>(observe => {
        observe.error()
      });
    }

    if (this.showAddNote()) {
      return this._executeCreate(noteComponent.getData());
    }
    else {
      return this._executeUpdate(noteComponent.getData());
    }
  }

  noteHasChanges(noteId: string, noteComponent: NoteComponent) {
    this.isDirty.set(noteId);
    this._noteComponent = noteComponent;
  }

  private _fetchNotes(): void {
    this.noteService.getNotes(this.linkedEntity, this.type).pipe(first())
      .subscribe({
        next: notes => {
          this.notes = notes.sort((a, b) => a.createdOn > b.createdOn ? -1 : 1);
          this.isDirty.set(undefined);
          this.showAddNote.set(false);
        }
      });
  }

  private _executeCreate(noteData: any): Observable<any> {
    return this.noteService.createNote(this.linkedEntity, this.type, noteData).pipe(first(), tap(
      () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notes.updated')
        });
        this._fetchNotes();
        this.countUpdated.emit();
        this.showAddNote.set(false);
      }
    ));
  }

  private _executeUpdate(noteData: any): Observable<any> {
    return this.noteService.updateNote(noteData).pipe(first(), tap(
      () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notes.updated')
        });
        this._fetchNotes();
      }
    ));
  }
}
