<div class='border-b border-b-grey-2 hover:bg-primary-hover px-5 py-3'>
  <div class='flex items-center justify-between ml-6 mb-3'>
    <span class='font-bold text-lg'>{{branchName}}{{branchCity ? " - " + branchCity : ""}}</span>
    <div class="flex items-center gap-2" *ngIf='!readOnly'>
      <p-button label='{{"enrollments.actions.validate" | translate}}'
                *ngIf='enrollmentRequest.stateId === EnrollmentRequestStatusKey.toValidate'
                (onClick)='menuItemAction(EnrollmentRequestActionEnum.validate)()'
      ></p-button>
      <alimento-ipv-frontend-more-actions [actions]='actions'></alimento-ipv-frontend-more-actions>
    </div>
  </div>
  <div class="flex w-full gap-2 mb-2 last:mb-0 text-lg {{participant?.validationResultId === ENROLLMENT_RESULT_ID.CANCEL ? 'text-grey-3 line-through' : ''}}"
       *ngFor='let participant of enrollmentRequest.participants'>
    <div class='w-4 h-4'>
    </div>
    <div class='flex-1' isEllipsis pTooltip>
      <span>{{ (participant.lastName || '') + ' ' + (participant.firstName || '') }}</span>
    </div>
    <div class='flex-1' isEllipsis pTooltip>
      <span *ngIf='participant.employerName'>{{ participant?.employerName }}{{participant.employerCity ? " - " + participant.employerCity : ""}}</span>
      <span *ngIf='!participant.employerName'>{{ branchName }}{{branchCity ? " - " + branchCity : ""}}</span>
    </div>
    <div class='basis-[240px] flex items-center gap-2' isEllipsis pTooltip>
      <span>{{ getWorkStatus(participant.workStatusId) | async }}</span>
      <i *ngIf='participant.enrollmentViaCevora'
         pTooltip='{{"enrollments.cevora" | translate}}'
         class='bg-alimento-green bg-opacity-30 px-1 rounded not-italic font-bold'>
        C
      </i>
    </div>
    <div class='basis-[150px]' isEllipsis pTooltip>
      <span *ngIf='enrollmentRequest'>{{ enrollmentRequest.createdOn | date : 'dd/MM/yyyy' }}</span>
    </div>
    <div class='basis-[40px] flex flex-col items-end'>
      <alimento-ipv-frontend-more-actions [actions]='participant?.validationResultId !== ENROLLMENT_RESULT_ID.CANCEL ? participantActions : []'
                                          [updateSelectedItem]="updateActionItem(participant)"
      ></alimento-ipv-frontend-more-actions>
    </div>
  </div>
</div>
