import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  EnrollmentStatusKey,
  OPTIONS_LIST_TYPE,
  ReferenceDataService
} from '@alimento-ipv-frontend/application-lib';
import { first } from 'rxjs';

@Component({
    selector: 'alimento-ipv-frontend-enrollment-status-chip',
    templateUrl: './enrollment-status-chip.component.html',
    standalone: false
})
export class EnrollmentStatusChipComponent implements OnChanges {
  @Input()
  status!: string;

  @Input()
  trainingOngoing: boolean | null = false;

  isCancelled: boolean | undefined = false;
  statusLabel: string;

  constructor(private referenceDataService: ReferenceDataService){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['status']?.currentValue) {
      this.referenceDataService.getReferenceDataItem(this.status, OPTIONS_LIST_TYPE.ENROLLMENT_STATUS).pipe(first())
        .subscribe(data => this.statusLabel = data.label);
    }
    else {
      this.statusLabel = "";
    }
  }

  getStatusClass(): string {
    if (this.status === EnrollmentStatusKey.Enrolled) {
      return "text-primary border border-primary ";
    }
    else {
      return "border-grey-3 text-grey-3";
    }
  }
}
