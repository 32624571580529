<div *ngIf='enrollment'
     class='shadow-grey-2 shadow-md my-2 p-4 bg-white hover:bg-primary-hover relative border border-input-border cursor-pointer'
>
  <div class='grid grid-cols-2 lg:grid-cols-3 gap-4'>
    <div class='flex flex-col gap-2 border-r border-r-grey-2 pr-2'>
      <div class='flex items-center gap-x-4 flex-wrap'>
        <span *ngIf="showName" class='font-bold'>{{ enrollment.person.alimentoId }} - {{ enrollment.person.lastName }} {{ enrollment.person.firstName }}</span>
        <span
          *ngIf='showName && enrollment.person.nationalIdentificationNumber'>{{ enrollment.person.nationalIdentificationNumber | insz }}</span>
        <alimento-ipv-frontend-enrollment-status-chip [status]='enrollment.statusId'
        ></alimento-ipv-frontend-enrollment-status-chip>
      </div>
      <span *ngIf='enrollment.branch?.data !== NO_BRANCH_ID'>
        {{ enrollment.branch.label }} - {{workStatus}}
        <i *ngIf='enrollment.via === ENROLLMENT_VIA.CEVORA'
           pTooltip='{{"enrollments.cevora" | translate}}'
           class='bg-alimento-green bg-opacity-30 px-1 rounded not-italic font-bold'>
          C
        </i>
      </span>
      <span *ngIf='enrollment.branch?.data === NO_BRANCH_ID'>{{workStatus}}</span>
    </div>
    <div class='flex flex-col gap-2'>
      <div class='flex flex-wrap items-center gap-2'>
        <span class='font-bold hover:underline'
              (click)='navigateToTraining($event)'>
          {{ enrollment.training.alimentoId }} - {{ enrollment.training.customTitle }}
        </span>
        <alimento-ipv-frontend-training-status-chip [status]='enrollment.training.statusId'
        ></alimento-ipv-frontend-training-status-chip>
      </div>
      <div class='flex items-center gap-x-4 flex-wrap'>
        <a class='hover:underline'
           *ngIf='enrollment.training.trainingAllowanceApplicationId'
           (click)='$event.stopImmediatePropagation()'
           [routerLink]='["/reimbursement-requests", enrollment.training.trainingAllowanceApplicationId]'>
          {{ 'reimbursementRequests.request' | translate }} - {{ enrollment.training.trainingAllowanceApplicationAlimentoId }}
        </a>
        <span>{{ trainingType }}</span>
        <div class='flex gap-2 items-center' *ngIf='enrollment.training.rubricId'>
          <i class='font-material text-lg'>folder</i>
          <span>{{ rubric }}</span>
        </div>
      </div>
    </div>
    <div class='lg:hidden'></div>
    <div class='flex flex-col gap-2 lg:items-end'>
      <div class='flex gap-2 items-center' *ngIf='enrollment.training.startDate || enrollment.training.endDate'>
        <i class='font-material text-lg'>calendar_month</i>
        <span>{{ enrollment.training.startDate | date : 'dd/MM/yyyy' }}
          - {{ enrollment.training.endDate | date : 'dd/MM/yyyy' }}</span>
      </div>

      <div class='flex gap-2 items-center' *ngIf='enrollment.training.caseManagerName'>
        <i class='font-material text-lg'>person</i>
        <span>{{ enrollment.training.caseManagerName }}</span>
      </div>
    </div>
  </div>
</div>
