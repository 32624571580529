<alimento-ipv-frontend-transition-popup
  #transitionPopupComponent
  [transitionAction]='currentTransitionAction'
  (formSubmit)="onTransitionPopupSubmit($event, transitionPopupComponent)"
  [person]='person'
></alimento-ipv-frontend-transition-popup>

<alimento-ipv-frontend-transition-action-dialog [transition]='currentTransition'
                                                [action]='currentTransitionAction'
                                                (submitDialog)='executeTransitionAction($event)'
></alimento-ipv-frontend-transition-action-dialog>

<alimento-ipv-frontend-header title="{{ 'persons.transitionsTitle' | translate }}">
  <p-button
    *ngIf='!readOnly'
    (onClick)="addTransition(transitionPopupComponent)"
    label="{{ 'persons.transitions.actions.add' | translate }}"
  ></p-button>
</alimento-ipv-frontend-header>

<div *ngIf="groupedTransitions">
  <p-tabs [(value)]='transitionIndex'>
    @let draftTransitions = getTransitions(TRANSITION_STATE.DRAFT);
    @let activeTransitions = getTransitions(TRANSITION_STATE.ACTIVE);
    @let rejectedTransitions = getTransitions(TRANSITION_STATE.REJECTED);
    @let closedTransitions = getTransitions(TRANSITION_STATE.CLOSED);
    <p-tablist>
      <p-tab value='0'>{{ 'persons.transitions.draftTransitions' | translate }} ({{ getTransitionsCount(draftTransitions) }})</p-tab>
      <p-tab value='1'>{{ 'persons.transitions.activeTransitions' | translate }} ({{ getTransitionsCount(activeTransitions) }})</p-tab>
      <p-tab value='2'>{{ 'persons.transitions.rejectedTransitions' | translate }} ({{ getTransitionsCount(rejectedTransitions) }})</p-tab>
      <p-tab value='3'>{{ 'persons.transitions.closedTransitions' | translate }} ({{ getTransitionsCount(closedTransitions) }})</p-tab>
    </p-tablist>
    <p-tabpanels>
      <p-tabpanel value="0">
        <ng-container *ngTemplateOutlet='transitionsTemplate; context: {transitions: draftTransitions}'></ng-container>
      </p-tabpanel>
      <p-tabpanel value="1">
        <ng-container *ngTemplateOutlet='transitionsTemplate; context: {transitions: activeTransitions}'></ng-container>
      </p-tabpanel>
      <p-tabpanel value="2">
        <ng-container *ngTemplateOutlet='transitionsTemplate; context: {transitions: rejectedTransitions}'></ng-container>
      </p-tabpanel>
      <p-tabpanel value="3">
        <ng-container *ngTemplateOutlet='transitionsTemplate; context: {transitions: closedTransitions}'></ng-container>
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>

  <ng-template #transitionsTemplate let-transitions="transitions">
    <div class="mt-4 flex flex-col gap-4">
      <div *ngFor='let branchTransition of transitions'>
        <div (click)='toggleCollapse(branchTransition.branchName)' class='flex items-center gap-2 cursor-pointer'>
          <i class="pi pi-angle-{{ !collapsed[branchTransition.branchName] ? 'up' : 'down' }}"></i>
          <span class='font-bold text-lg'>{{branchTransition.branchName}}</span>
        </div>
        <div *ngIf='!collapsed[branchTransition.branchName]'>
          <alimento-ipv-frontend-transition-card *ngFor='let transition of branchTransition.transitions'
                                                 (actionClicked)='transitionAction($event, transitionPopupComponent)'
                                                 [readOnly]='readOnly'
                                                 [activeTransitionId]='activeTransitionId'
                                                 [transition]='transition'>
          </alimento-ipv-frontend-transition-card>
        </div>
      </div>

      <div class="" *ngIf="transitions.length === 0">
        <span>{{ 'persons.transitions.noTransitions' | translate }}</span>
      </div>
    </div>
  </ng-template>
</div>
