<div [formGroup]="formGroup">
  <alimento-ipv-frontend-header title="{{ 'trainings.descriptionData.formTitle' | translate }}">
  </alimento-ipv-frontend-header>

  <alimento-ipv-frontend-form-field
    *ngIf='formGroup.contains("summary")'
    [formGroup]='formGroup'
    [errorTemplate]='summaryError'
    fieldKey='trainings.descriptionData.summary'
    labelClass='text-lg text-[#1A1A1A] font-bold mb-2'
    formField='summary'
  >
    <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('summary')?.value}}</span>
    <textarea *ngIf='formGroup.enabled' rows="5" class="w-full" formControlName="summary" pTextarea></textarea>
    <ng-template #summaryError>
      <small *ngIf='formGroup.get("summary")?.hasError("maxlength")'>
        {{ 'validation.maxLength' | translate: {number: 4000} }}
      </small>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <ng-template #editorHeader>
    <div class="flex items-center">
      <span class="ql-formats">
          <button aria-label="Bold" class="ql-bold"></button>
          <button aria-label="Italic" class="ql-italic"></button>
          <button aria-label="underline" class="ql-underline"></button>
          <button aria-label="link" class="ql-link"></button>
        </span>
      <span class="ql-formats">
          <button type="button" class="ql-list" aria-label="List" value="ordered"></button>
          <button type="button" class="ql-list" aria-label="Bullet" value="bullet"></button>
        </span>
    </div>
  </ng-template>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    [errorTemplate]='descriptionError'
    [required]='true'
    [includeTouchedASInvalid]='false'
    fieldKey='trainings.descriptionData.description'
    labelClass='text-lg text-[#1A1A1A] font-bold mb-2'
    formField='description'
  >
    <span *ngIf='formGroup.disabled' class='read-only-field' [innerHTML]="formGroup.get('description')?.value"></span>
    <p-editor
      #description
      *ngIf='formGroup.enabled'
      formControlName="description"
      (onTextChange)="textChange('description', $event)"
      (onSelectionChange)="editorSelectionChange($event, description)"
      [style]="{ height: '320px' }"
    >
      <p-header>
        <ng-container *ngTemplateOutlet="editorHeader"></ng-container>
      </p-header>
    </p-editor>

    <ng-template #descriptionError>
      <small *ngIf='formGroup.get("description")?.hasError("required")'>
        {{ 'error.field-required' | translate:{field: 'trainings.descriptionData.description' | translate} }}
      </small>
      <small *ngIf='formGroup.get("description")?.hasError("maxLength")'>
        {{ 'validation.maxLength' | translate: {number: 4000} }}
      </small>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <alimento-ipv-frontend-form-field
    *ngIf='formGroup.contains("certificateSummary")'
    [formGroup]='formGroup'
    [errorTemplate]='certificateSummaryError'
    fieldKey='trainings.descriptionData.certificateSummary'
    labelClass='text-lg text-[#1A1A1A] font-bold mb-2'
    formField='certificateSummary'
  >
    <span *ngIf='formGroup.disabled' class='read-only-field' [innerHTML]="formGroup.get('certificateSummary')?.value"></span>
    <p-editor
      #certificateSummary
      *ngIf='formGroup.enabled'
      formControlName="certificateSummary"
      (onTextChange)="textChange('certificateSummary', $event)"
      (onSelectionChange)="editorSelectionChange($event, certificateSummary)"
      [style]="{ height: '320px' }"
    >
      <p-header>
        <ng-container *ngTemplateOutlet="editorHeader"></ng-container>
      </p-header>
    </p-editor>

    <ng-template #certificateSummaryError>
      <small *ngIf='formGroup.get("certificateSummary")?.hasError("maxLength")'>
        {{ 'validation.maxLength' | translate: {number: 4000} }}
      </small>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <div class="field field-checkbox">
    <p-checkbox *ngIf='formGroup.contains("smeBrochure")'
                formControlName="smeBrochure"
                [binary]="true"
                inputId='smeBrochure'
    ></p-checkbox>
    <label for='smeBrochure'>{{'trainings.descriptionData.kmoBrochure' | translate}}</label>
  </div>
</div>
