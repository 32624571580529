<div *ngIf='transition'
     class="flex items-start shadow-grey-2 shadow-md my-2 p-4 bg-white hover:bg-primary-hover relative border border-input-border cursor-pointer">
  <div class='w-full grid grid-cols-2 lg:grid-cols-3 gap-4 items-start'>
    <div class='flex flex-col gap-2 border-r border-grey-2 pr-2'>
      <div class='flex items-center gap-x-4 flex-wrap'>
        <span class='font-bold whitespace-pre-line'>{{transition.employment.person.alimentoId}} - {{transition.employment.person.lastName}} {{transition.employment.person.firstName}}</span>
        <span>{{transition.employment.person.nationalIdentificationNumber | insz}}</span>
      </div>
      <div class='flex items-center gap-x-4 flex-wrap'>
        <span>{{transition.employment.branch.name}} - {{transition.employment.branch.city}}</span>
        <span>{{workStatus}}</span>
      </div>
    </div>
    <div class='flex flex-col gap-x-2 flex-wrap'>
      <div class='flex items-center gap-4'>
        <span class='font-bold'>{{type?.label}}</span>
        <span class='text-primary'>{{status}}</span>
      </div>
      <span *ngIf='rubrics'>{{rubrics}}</span>
      <span *ngIf='transition.employment.function && transition.typeId === TRANSITION_TYPE.FUNCTION_CHANGE'>
        {{transition.employment.function}}
      </span>
    </div>
    <div class='lg:hidden'></div>
    <div class='flex flex-col lg:items-end gap-x-2 flex-wrap'>
      <span *ngIf='transition.validUntil && transition.validFrom'>
        {{'persons.transitions.validFromUntil' | translate:{
        from: transition.validFrom | date: "dd/MM/yyyy", until: transition.validUntil | date: "dd/MM/yyyy"} }}
      </span>
      <span *ngIf='transition.validFrom && !transition.validUntil'>
        {{"persons.transitions.validFrom" | translate}} {{transition.validFrom | date: "dd/MM/yyyy"}}
      </span>
      <span *ngIf='transition.validUntil && !transition.validFrom'>
        {{"persons.transitions.validTo" | translate}} {{transition.validUntil | date: "dd/MM/yyyy"}}
      </span>

      <div class='flex items-center gap-x-4 flex-wrap lg:justify-end' *ngIf='transition.statusId === TRANSITION_STATE.ACTIVE || transition.statusId === TRANSITION_STATE.CLOSED'>
        <span>{{'persons.transitions.totalBudget' | translate}}: {{transition.totalBudget | currency:"EUR"}}</span>
        <span>{{'persons.transitions.remainingBudget' | translate}}: {{transition.remainingBudget | currency:"EUR"}}</span>
      </div>
    </div>
  </div>
</div>
