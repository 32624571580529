<div class="sticky z-10 flex items-center justify-between gap-2 bg-white border-b border-grey-2 px-8 py-4 top-[65px]">
  <div class='flex items-center gap-2'>
    <h1 class="mt-0 text-text">{{ header | translate }}</h1>
    <ng-content select='[top-left]'></ng-content>
  </div>
  <ng-content select="[top-right]"></ng-content>
</div>

<div class='mt-4 mx-8'>
  <ng-content></ng-content>
</div>
