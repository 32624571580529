<div *ngIf="reimbursement" class='pb-2'>
  <ng-container *ngTemplateOutlet='reimbursementTemplate; context: {reimbursement: reimbursement}'></ng-container>

  <div *ngFor='let correction of corrections'>
    <ng-container *ngTemplateOutlet='reimbursementTemplate; context: {reimbursement: correction}'></ng-container>
  </div>
</div>

<ng-template #reimbursementTemplate let-reimbursement="reimbursement">
  <div class="grid w-full items-center grid-cols-1 lg:grid-cols-[200px_minmax(0,_1fr)_minmax(0,_1fr)_minmax(0,_1fr)] hover:bg-primary hover:bg-opacity-[7%] py-2 px-4 gap-4 mr-2">
    <div class='flex items-center gap-2'>
      <span>{{reimbursement.amount | currency: "EUR"}}</span>
      <span *ngIf='reimbursement.reimbursementTypeId === REIMBURSEMENT_TYPE.CORRECTION'>{{'reimbursements.correction' | translate}}</span>
    </div>
    <div class='flex gap-4'>
      <span>{{reimbursement?.transitionPersonName}}</span>
      <span class='text-grey-3' *ngIf='reimbursement.transitionTypeId'>{{getTransitionType(reimbursement.transitionTypeId) | async}}</span>
    </div>
    <div class='flex flex-col gap-2'>
      <span *ngIf='reimbursement.remarks'>
        {{reimbursement.remarks}}
      </span>
      <span *ngIf='reimbursement.externalRemarks'>
        {{reimbursement.externalRemarks}}
      </span>
    </div>
    <div class="lg:justify-end flex items-center flex-nowrap gap-4">
      <div *ngIf='reimbursement.statusId === REIMBURSEMENT_STATUS.CONFIRMED'>
        <span>{{'reimbursements.confirmForPaymentDate' | translate:{date: reimbursement.statusReachedDate | date: "dd/MM/yyyy"} }}</span>
      </div>
      <div *ngIf='reimbursement.statusId === REIMBURSEMENT_STATUS.PAYED'>
        <span>{{'reimbursements.paymentDate' | translate:{date: reimbursement.statusReachedDate | date: "dd/MM/yyyy"} }}</span>
      </div>
      <div *ngIf='reimbursement.statusId === REIMBURSEMENT_STATUS.CALCULATED'>
        <p-button
          *ngIf="!this.readOnly"
          [disabled]='!canReimburse'
          (onClick)="toggleAction(reimbursement, REIMBURSEMENT_ACTION_ENUM.confirmForPayment, $event)"
          label="{{ 'reimbursements.confirmForPayment' | translate  }}"
        ></p-button>
      </div>
      <alimento-ipv-frontend-more-actions [actions]="actions[reimbursement.reimbursementId]"></alimento-ipv-frontend-more-actions>
    </div>
  </div>
</ng-template>

