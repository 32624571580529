import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  TransitionAction,
  TransitionEnrollment,
  TransitionItem,
  TransitionType
} from '../../../types/person.type';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { combineLatest, first, map, Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { TRANSITION_STATE, TRANSITION_TYPE, TransitionActionEnum } from '../../../types/person.enum';
import { TranslateService } from '@ngx-translate/core';
import { TrainingMapper } from '../../../utils/mapper/training.mapper';
import { Router } from '@angular/router';

@Component({
    selector: 'alimento-ipv-frontend-transition-card',
    templateUrl: './transition-card.component.html',
    standalone: false
})
export class TransitionCardComponent implements OnChanges {
  @Input()
  transition?: TransitionItem;

  @Input()
  readOnly: boolean;

  @Input()
  activeTransitionId?: string;

  @Output()
  actionClicked = new EventEmitter<TransitionAction>();

  type: TransitionType;
  rubrics: string;
  actions: MenuItem[] = [];
  status: string;
  expanded = false;

  protected readonly TRANSITION_STATE = TRANSITION_STATE;
  protected readonly TransitionActionEnum = TransitionActionEnum;
  protected readonly TRANSITION_TYPE = TRANSITION_TYPE;

  constructor(private referenceDataService: ReferenceDataService,
              private translateService: TranslateService,
              private router: Router,
              private trainingMapper: TrainingMapper) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['transition']?.currentValue) {
      this.referenceDataService.getTransitionType(this.transition.transitionTypeId).pipe(first())
        .subscribe(type => {
          this.type = type;
          this._setMenuItems();
        });

      if (this.transition?.rubricIds) {
        combineLatest(this.transition.rubricIds
          .map(rubricId => this.referenceDataService.getTrainingRubric(rubricId).pipe(first(),
            map(rubric => rubric.label))))
          .subscribe(rubrics => this.rubrics = rubrics.join(', '));
      }

      this.referenceDataService.getTransitionStatus(this.transition?.transitionStatusId).pipe(first())
        .subscribe(status => this.status = status.label);
    }
  }

  onActionClicked(action: TransitionActionEnum): void {
    this.actionClicked.emit({transition: this.transition, action: action});
  }

  private _setMenuItems(): void {
    if (this.transition?.transitionStatusId === TRANSITION_STATE.DRAFT) {
      this.actions = [
        {
          label: this.translateService.instant("persons.transitions.actions.edit"),
          command: () => this.onActionClicked(TransitionActionEnum.edit)
        },
        {
          label: this.translateService.instant("persons.transitions.actions.reject"),
          command: () => this.onActionClicked(TransitionActionEnum.reject)
        },
        {
          label: this.translateService.instant("persons.transitions.actions.delete"),
          command: () => this.onActionClicked(TransitionActionEnum.delete),
          icon: "pi pi-trash",
          styleClass: 'danger',
          iconStyle: {
            position: 'absolute',
            right: '2px'
          }
        }
      ]
    }
    else if (this.transition?.transitionStatusId === TRANSITION_STATE.ACTIVE) {
      this.actions = [
        {
          label: this.translateService.instant("persons.transitions.actions.close"),
          command: () => this.onActionClicked(TransitionActionEnum.close)
        },
      ];

      const parentType = this.type?.parent || this.type.data;
      if (parentType === TRANSITION_TYPE.FUNCTION_CHANGE || parentType === TRANSITION_TYPE.RESUMPTION) {
        this.actions.unshift({
          label: this.translateService.instant("persons.transitions.actions.edit"),
          command: () => this.onActionClicked(TransitionActionEnum.edit)
        });
      }
    }
    else if (this.transition?.transitionStatusId === TRANSITION_STATE.REJECTED) {
      this.actions = [
        {
          label: this.translateService.instant("persons.transitions.actions.reopen"),
          command: () => this.onActionClicked(TransitionActionEnum.reopen)
        },
      ];
    }
    else if (this.transition?.transitionStatusId === TRANSITION_STATE.CLOSED) {
      this.actions = [
        {
          label: this.translateService.instant("persons.transitions.actions.reactivate"),
          command: () => this.onActionClicked(TransitionActionEnum.reactivate)
        },
      ];
    }
    else {
      this.actions = [];
    }
  }

  getTrainingType(typeId: string): Observable<string> {
    return this.referenceDataService.getReferenceDataItem(typeId, OPTIONS_LIST_TYPE.TRAINING_TYPES)
      .pipe(map(result => result.label));
  }

  navigateToTraining(enrollment: TransitionEnrollment, event: MouseEvent): void {
    const path = this.trainingMapper.getNavigationPath(enrollment.training.id, enrollment.training.typeId);
    if (event.ctrlKey) {
      window.open(path.join('/'), '_blank');
    }
    else {
      this.router.navigate(path);
    }
  }
}
