import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG_TOKEN, AppConfig, Note } from '../types/ui-lib.type';
import { NOTES_TYPE } from '../types/ui-lib.enum';

@Injectable({
  providedIn: "root"
})
export class NoteService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {}

  getNotes(linkedEntityId: string, type: NOTES_TYPE): Observable<Note[]> {
    return this.http.get<Note[]>(`${this.config.readApiUrl}/notes`, {params: {linkedEntityId: linkedEntityId, type: type}});
  }

  getNotesCount(linkedEntityId: string, type: NOTES_TYPE): Observable<number> {
    return this.http.get<number>(`${this.config.readApiUrl}/notes/count`, {params: {linkedEntityId: linkedEntityId, type: type}});
  }

  createNote(linkedEntityId: string, type: NOTES_TYPE, note: Note): Observable<{ id: string }> {
    const data = note as any;
    data.entityId = linkedEntityId;
    data.type = type;
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/notes`, data);
  }

  updateNote(note: Note): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/notes/${note.id}`, note);
  }

  deleteNote(note: Note): Observable<any> {
    return this.http.delete(`${this.config.writeApiUrl}/notes/${note.id}`);
  }
}
