import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { TrainingMapper } from '../../../utils/mapper/training.mapper';
import { ENROLLMENT_VIA, EnrollmentSearchItem } from '@alimento-ipv-frontend/application-lib';
import { NO_BRANCH_ID } from '@alimento-ipv-frontend/ui-lib';

@Component({
    selector: 'alimento-ipv-frontend-training-enrollment-card',
    templateUrl: './training-enrollment-card.component.html',
    standalone: false
})
export class TrainingEnrollmentCardComponent implements OnChanges {

  @Input()
  enrollment: EnrollmentSearchItem;

  @Input()
  showName: boolean = true;

  trainingType: string;
  rubric: string;
  workStatus: string;

  protected readonly ENROLLMENT_VIA = ENROLLMENT_VIA;
  protected readonly NO_BRANCH_ID = NO_BRANCH_ID;

  constructor(private referenceDataService: ReferenceDataService,
              private trainingMapper: TrainingMapper,
              private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enrollment']?.currentValue) {
      this.referenceDataService.getTrainingType(this.enrollment.training.typeId).pipe(first())
        .subscribe(type => this.trainingType = type.label);

      if (this.enrollment.training.rubricId) {
        this.referenceDataService.getTrainingRubric(this.enrollment.training.rubricId).pipe(first())
          .subscribe(rubric => this.rubric = rubric.label);
      }

      if (this.enrollment.workStatusId) {
        this.referenceDataService.getReferenceDataItem(this.enrollment.workStatusId, OPTIONS_LIST_TYPE.WORK_STATUSES).pipe(first())
          .subscribe(workStatus => this.workStatus = workStatus.label);
      }
    }
  }

  navigateToTraining(event: MouseEvent): void {
    const path = this.trainingMapper.getNavigationPath(this.enrollment.training.id, this.enrollment.training.typeId);
    if (event.ctrlKey) {
      window.open(path.join('/') + "?enrollmentId=" + this.enrollment.id, '_blank');
    }
    else {
      this.router.navigate(path, {queryParams: {enrollmentId: this.enrollment.id}});
    }
  }
}
