import { Component, OnDestroy, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import {
  AuthorizationService,
  CanComponentDeactivate,
  ExtraMenuItem,
  LeaveConfirmService,
  MenuItemUtilsService,
  MyMessageService,
  NavigationService,
  NOTES_TYPE, NoteService,
  NotesSidebarComponent,
  Role,
  TabMenuItem,
  TitleService,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { first, forkJoin, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PaidEducationalLeaveAttest,
  PaidEducationalLeaveContactEvent,
  paidEducationalLeaveHourUpdateEvent
} from '../../../types/paid-educational-leave.type';
import { PaidEducationalLeaveService } from '../../../services/paid-educational-leave.service';
import { PersonService } from '../../../services/person.service';
import { BranchService } from '../../../services/branch.service';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { Person } from '../../../types/person.type';
import { Branch, ContactPerson } from '../../../types/branch.type';
import { RESPONSIBILITY } from '../../../types/branch.enum';
import { EmploymentService } from '../../../services/employment.service';
import { MailContextType } from '../../../types/communications.enum';
import {
  PAID_EDUCATIONAL_LEAVE_ACTION,
  PAID_EDUCATIONAL_LEAVE_ATTEST_STATE,
  PAID_EDUCATIONAL_LEAVE_HOURS_STATE,
  PAID_EDUCATIONAL_LEAVE_TYPE
} from '../../../types/paid-educational-leave.enum';
import {
  PaidEducationalLeaveActionDialogComponent
} from '../../components/paid-educational-leave-action-dialog/paid-educational-leave-action-dialog.component';
import {
  PageDetailCommunicationsComponent
} from '../../../communications/components/page-detail-communications/page-detail-communications.component';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'alimento-ipv-frontend-paid-educational-leave-detail',
  templateUrl: './paid-educational-leave-detail.component.html',
  standalone: false
})
export class PaidEducationalLeaveDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  paidEducationalLeaveId: string;
  paidEducationalLeaveAttest: PaidEducationalLeaveAttest;

  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  extraActions: MenuItem[];
  breadcrumb: MenuItem[];
  activeTabIndex = 0;
  readOnly = false;
  showNotes = false;
  notesHasChanges: boolean;
  canRequestAttest: boolean;
  canGenerate: boolean;
  canSend: boolean;
  loading = false;
  totalHours: string;
  notesCount: WritableSignal<number> = signal(undefined);

  type: string;
  person: Person;
  branch: Branch;
  contactPersons: ContactPerson[];
  schoolYear: string;
  workStatus: string;
  educationalLeaveTitle: string;
  reasonCancelled: string;
  PaidEducationalLeaveNote = NOTES_TYPE.PaidEducationalLeaveNote;
  currentAction: PAID_EDUCATIONAL_LEAVE_ACTION;

  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;

  @ViewChild(PaidEducationalLeaveActionDialogComponent)
  paidEducationalLeaveActionDialog: PaidEducationalLeaveActionDialogComponent;

  @ViewChild(PageDetailCommunicationsComponent)
  communicationComponent: PageDetailCommunicationsComponent;

  private _getCommunicationCountSub: Subscription;
  private _subscriptions: Subscription[] = [];
  protected readonly MailContextType = MailContextType;
  protected readonly PAID_EDUCATIONAL_LEAVE_ACTION = PAID_EDUCATIONAL_LEAVE_ACTION;
  protected readonly PAID_EDUCATIONAL_LEAVE_ATTEST_STATE = PAID_EDUCATIONAL_LEAVE_ATTEST_STATE;

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private titleService: TitleService,
    private authorizationService: AuthorizationService,
    private paidEducationalLeaveService: PaidEducationalLeaveService,
    private personService: PersonService,
    private referenceDataService: ReferenceDataService,
    private branchService: BranchService,
    private employmentService: EmploymentService,
    private messageService: MyMessageService,
    private leaveConfirmationService: LeaveConfirmService,
    private navigationService: NavigationService,
    private menuItemService: MenuItemUtilsService,
    private noteService: NoteService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this._createMenuItems();
    this._createBreadcrumb();
  }

  ngOnInit() {
    this.route.params.pipe(first())
      .subscribe(params => {
        this.paidEducationalLeaveId = params['paidEducationalLeaveId'];
        if (this.paidEducationalLeaveId) {
          this._fetchData();
        }
        else {
          this._navigateToErrorPage();
        }
      });

    this.route.queryParams.subscribe(
      (queryParams) => (this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0)
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._getCommunicationCountSub?.unsubscribe();
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.notesHasChanges) {
      return true;
    }

    const saveActions: Observable<any>[] = [];
    if (this.notesHasChanges) {
      saveActions.push(this.notesSidebarComponent.saveChanges());
    }

    return this.leaveConfirmationService.leaveDialog(() => forkJoin(saveActions));
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem) {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  private _fetchData(): void {
    this.paidEducationalLeaveService.getpaidEducationalLeave(this.paidEducationalLeaveId)
      .pipe(first())
      .subscribe({
        next: paidEducationalLeaveAttest => {
          this.paidEducationalLeaveAttest = paidEducationalLeaveAttest;

          forkJoin([
            this.personService.getPerson(this.paidEducationalLeaveAttest.personId).pipe(first()),
            this.branchService.getBranch(this.paidEducationalLeaveAttest.branchId).pipe(first()),
            this.branchService.getContactPersons(this.paidEducationalLeaveAttest.branchId).pipe(first()),
            this.referenceDataService.getReferenceDataItem(this.paidEducationalLeaveAttest.typeId, OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_TYPES).pipe(first())
          ])
            .subscribe(([person, branch, contactPersons, type]) => {
              this.person = person;
              this.branch = branch;
              this.type = type.label;
              this.contactPersons = contactPersons.filter(contactPerson =>
                contactPerson.responsibilities.map(responsibility => responsibility.data).includes(RESPONSIBILITY.BEV));
              this.titleService.setTitle([this.type, this.paidEducationalLeaveAttest.alimentoId + '']);
              this._createMenuItems();
              this._createBreadcrumb();

              this.schoolYear = this.paidEducationalLeaveAttest.schoolYear + '-' + (this.paidEducationalLeaveAttest.schoolYear + 1);

              this.referenceDataService.getReferenceDataItem(this.paidEducationalLeaveAttest.workStatusId, OPTIONS_LIST_TYPE.WORK_STATUSES)
                .pipe(first())
                .subscribe(workStatus => this.workStatus = workStatus.label);

              this.referenceDataService.getReferenceDataItem(this.paidEducationalLeaveAttest.educationalLeaveTitleId, OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_TITLES)
                .pipe(first())
                .subscribe(title => this.educationalLeaveTitle = title.label);

              this.referenceDataService.getReferenceDataItem(this.paidEducationalLeaveAttest.reasonCancelledId, OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_CANCEL_REASONS)
                .pipe(first())
                .subscribe(title => this.reasonCancelled = title.label);

              this.canRequestAttest = paidEducationalLeaveAttest.typeId === PAID_EDUCATIONAL_LEAVE_TYPE.BEV &&
                [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.APPROVED, PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.ON_HOLD].includes(paidEducationalLeaveAttest.statusId) && this.contactPersons?.length > 0;
              this.canGenerate = paidEducationalLeaveAttest.typeId === PAID_EDUCATIONAL_LEAVE_TYPE.BEV &&
                [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.APPROVED, PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.ON_HOLD].includes(paidEducationalLeaveAttest.statusId);
              this.canSend = paidEducationalLeaveAttest.typeId === PAID_EDUCATIONAL_LEAVE_TYPE.BEV &&
                [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.GENERATED].includes(paidEducationalLeaveAttest.statusId) && this.contactPersons?.length > 0;

              this._calculateTotalHours();
              this.loadNotesCount();
            });
        },
        error: () => {
          this._navigateToErrorPage();
        }
      });
  }

  private _navigateToErrorPage(): void {
    this.router.navigate(['error'], {
      state: {
        message: this.translateService.instant('error.itemWithIdDoesNotExist', {
          item: this.translateService.instant('paidEducationalLeaves.menuTitle'),
          id: this.paidEducationalLeaveId
        }),
        redirectUrl: '/'
      }
    });
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.tabMenuItems = [
          { name: 'basic', index: 0, title: this.translateService.instant('paidEducationalLeaves.basicData') }
        ];

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('branches.notesTitle'),
            disabled: !this.paidEducationalLeaveId,
            count: this.notesCount,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          }
        ];
      })
    );
  }

  private _createBreadcrumb(): void {
    this.breadcrumb = [
      {
        label: this.translateService.instant('titles.paidEducationalLeave.list'),
        routerLink: '/paid-educational-leaves'
      },
      {
        label: this.paidEducationalLeaveAttest?.alimentoId + ' - ' + this.person?.lastName + ' ' + this.person?.firstName
      }
    ];
  }

  setExtraActions(): void {
    this.extraActions = [];

    if (this.paidEducationalLeaveAttest.statusId !== PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.GENERATED &&
      this.paidEducationalLeaveAttest.statusId !== PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.CANCELLED) {
      this.extraActions.push(this.menuItemService.getMenuItem('paidEducationalLeaves.actions.cancel',
        () => this.openActionDialog(PAID_EDUCATIONAL_LEAVE_ACTION.CANCEL)));
    }
    if (this.paidEducationalLeaveAttest.statusId === PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.APPROVED) {
      this.extraActions.push(this.menuItemService.getMenuItem('paidEducationalLeaves.actions.putOnHold',
        () => this.openActionDialog(PAID_EDUCATIONAL_LEAVE_ACTION.PUT_ON_HOLD)));
    }
  }

  sessionUpdateEvent(event: paidEducationalLeaveHourUpdateEvent): void {
    this.paidEducationalLeaveService.updatepaidEducationalLeaveHours(event.paidEducationalLeaveAttestId, event.hours as any).pipe(first())
      .subscribe({
        next: () => {
          this.paidEducationalLeaveAttest.paidEducationalLeaveHours.forEach(paidEducationalLeaveHour => {
            const matchingHour = event.hours.filter(hour => hour.id === paidEducationalLeaveHour.id)[0];
            if (matchingHour) {
              paidEducationalLeaveHour.paidEducationalLeaveHourStatusId = matchingHour.paidEducationalLeaveHourStatusId;
            }
          });
          this._calculateTotalHours();
          if (this.contactPersons?.length > 0 && event.allValidated) {
            this._fetchData();
          }
        }
      });
  }

  updateContactPerson(event: PaidEducationalLeaveContactEvent): void {
    event.setLoading(true);
    this.employmentService.getEmploymentsOfPersonByBranch(event.branchId, event.contactPersonId).pipe(first())
      .subscribe({
        next: employment => {
          const employmentData = employment as any;
          employmentData.responsibilityIds = (employment.responsibilities || []).map(responsibility => responsibility.data);
          employmentData.responsibilityIds.push(RESPONSIBILITY.BEV);
          delete employmentData.responsibilities;
          employmentData.workStatusId = employment.workStatus?.data;
          delete employmentData.workStatus;

          this.employmentService.updateEmployment(event.contactPersonId, employment.employmentId, employmentData)
            .pipe(first())
            .subscribe({
              next: () => {
                event.setLoading(false);
                this.messageService.success();
                event.closeDialog();

                this._fetchData();
              },
              error: () => event.setLoading(false)
            });
        },
        error: () => event.setLoading(false)
      });
  }

  executeAction(event: any): void {
    event.setLoading(true);
    let request$;
    if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.ATTEST_REQUESTED) {
      request$ = this.paidEducationalLeaveService.requestAttestNeeded([this.paidEducationalLeaveId]);
    }
    else if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.GENERATE) {
      request$ = this.paidEducationalLeaveService.generateAttest([this.paidEducationalLeaveId]);
    }
    else if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.SEND) {
      request$ = this.paidEducationalLeaveService.sendAttest([this.paidEducationalLeaveId]);
    }
    else if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.CANCEL) {
      request$ = this.paidEducationalLeaveService.cancelAttest(this.paidEducationalLeaveId, event.data);
    }
    else if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.REACTIVATE) {
      request$ = this.paidEducationalLeaveService.reactivateAttest(this.paidEducationalLeaveId);
    }
    else if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.PUT_ON_HOLD) {
      request$ = this.paidEducationalLeaveService.putAttestOnHold(this.paidEducationalLeaveId);
    }

    request$.pipe(first()).subscribe({
      next: () => {
        this.messageService.success('actionExecuted');
        event.setLoading(false);
        this.paidEducationalLeaveActionDialog.closeDialog();
        this._fetchData();
        this.communicationComponent?.checkForNewCommunication();
      },
      error: () => {
        event.setLoading(false);
      }
    });
  }

  openActionDialog(action: PAID_EDUCATIONAL_LEAVE_ACTION): void {
    this.currentAction = action;
    this.paidEducationalLeaveActionDialog.open();
  }

  private _setDetailUrl(): void {
    if (this.paidEducationalLeaveId) {
      this.navigationService.replaceState(
        this.router
          .createUrlTree(['/paid-educational-leaves', this.paidEducationalLeaveId], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _calculateTotalHours(): void {
    function getTimeMinutes(duration: string) {
      const split = duration.split(':');
      return Number(split[1]) + (Number(split[0]) * 60);
    }

    const sum = this.paidEducationalLeaveAttest.paidEducationalLeaveHours
      .filter(session => session.paidEducationalLeaveHourStatusId === PAID_EDUCATIONAL_LEAVE_HOURS_STATE.VALIDATED ||
        session.paidEducationalLeaveHourStatusId === PAID_EDUCATIONAL_LEAVE_HOURS_STATE.PROPOSED)
      .filter(session => session.registeredHours)
      .reduce((sum, current) => {
        if (current.paidEducationalLeaveHourStatusId !== PAID_EDUCATIONAL_LEAVE_HOURS_STATE.NOT_NEEDED) {
          sum += getTimeMinutes(current.registeredHours);
        }
        return sum;
      }, 0);

    this.totalHours = ('' + Math.floor(sum / 60)).padStart(2, '0') + ':' +
      ('' + Math.floor(sum % 60)).padStart(2, '0');
  }

  loadNotesCount(): void {
    this.noteService.getNotesCount(this.paidEducationalLeaveId, NOTES_TYPE.PaidEducationalLeaveNote).pipe(first())
      .subscribe(count => {
        this.notesCount.set(count);
      });
  }
}
