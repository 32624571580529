import { Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { first, Observable, Subscription } from 'rxjs';
import {
  FilterType,
  InfiniteScrollDataAdapter, LazyDropdownComponent,
  PaginatedResponse,
  SearchFilterValue,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { BranchListItem } from '../../../types/searches.type';
import { SearchesService } from '../../../services/searches.service';
import { Branch } from '../../../types/branch.type';
import { BranchService } from '../../../services/branch.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'alimento-ipv-frontend-branch-select',
    templateUrl: './branch-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BranchSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BranchSelectComponent),
            multi: true
        }
    ],
    standalone: false
})
export class BranchSelectComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input()
  formControl: FormControl<BranchListItem> = new FormControl<BranchListItem>(undefined);

  @Input()
  extraFilters: SearchFilterValue[] = [];

  @Input()
  placeholder = 'branches.searchPlaceholder';

  @Input()
  activeOnly = true;

  data$: InfiniteScrollDataAdapter<BranchListItem>;

  private _subscriptions: Subscription[] = [];
  private NUMBER_OF_LOADED_ITEMS = 30;

  @ViewChild(LazyDropdownComponent)
  private branchInput: LazyDropdownComponent;

  constructor(private searchesService: SearchesService, private branchService: BranchService, private translateService: TranslateService) {
    this._createDataSource();
    this._subscriptions.push(
      this.formControl.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['extraFilters']?.currentValue) {
      this._createDataSource();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  focus(): void {
    setTimeout(() => this.branchInput.setFocus());
  }

  setSearchValue(value: string): void {
    setTimeout(() => this.branchInput.inputControl.setValue(value));
  }

  _createDataSource(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: this.NUMBER_OF_LOADED_ITEMS,
      filters: []
    };

    if (this.activeOnly) {
      searchRequest.filters.push({
        type: FilterType.isActive,
        values: [true]
      });
    }

    this.extraFilters.forEach(extraFilter => searchRequest.filters.push(extraFilter));

    this.data$ = new InfiniteScrollDataAdapter<BranchListItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<BranchListItem>> => {
      return this.searchesService.searchBranches(searchRequest);
    }, searchRequest, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  getSelectedString: ((item: any) => string) = (item: BranchListItem) => {
    return item.name + (item.isActive === false ? ' - ' + this.translateService.instant("branches.inActive") : '') + ' - ' + item.address;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value && (typeof value === 'string')) {
      this.branchService
        .getBranch(value)
        .pipe(first())
        .subscribe((branch) =>
          this.formControl.setValue(this._getBranchListItem(branch), { emitEvent: false }));
    }
    else if (value) {
      const branch = value.branchName ? this._getBranchListItem(value) : value;
      this.formControl.setValue(branch, {emitEvent: false});
    }
  }

  private _getBranchListItem(branch: Branch): BranchListItem {
    return {
      branchId: branch.branchId,
      alimentoId: "" + branch.branchAlimentoId,
      name: branch.branchName,
      address: branch.city,
      companyNumber: branch.companyNumber,
      isFood: branch.isFood,
      isFishing: branch.isFishing,
      isLocation: branch.isLocation,
      isFoodTrade: branch.isFoodTrade,
      isActive: branch.active,
      isTeacher: branch.isTeacher,
      numberOfEmployees: branch.numberOfWorkersPC118 + branch.numberOfClerksPC220,
      rszNumber: branch.rszNumber,
      vatNumber: branch.vatNumber,
      isMainBranch: null,
      enterpriseId: branch.enterpriseId
    };
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate() {
    return this.formControl.valid ? null : { branch: { valid: false } };
  }

  clearBranch(): void {
    this.formControl.setValue(null);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable( {emitEvent: false});
    }
    else {
      this.formControl.enable({emitEvent: false});
    }
  }
}
