<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>
<div *ngIf="paidEducationalLeaveAttest">
  <alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                       [linkedEntity]='paidEducationalLeaveId'
                                       [readonly]='readOnly'
                                       (hasChanges)='notesHasChanges = $event'
                                       (countUpdated)='loadNotesCount()'
                                       [type]='PaidEducationalLeaveNote'>
  </alimento-ipv-frontend-notes-sidebar>

  <alimento-ipv-frontend-detail-page-layout
    header="{{ type }}"
    [breadcrumb]='breadcrumb'
    fallbackUrl='/paid-educational-leaves'
  >
    <ng-container status>
      <alimento-ipv-frontend-paid-educational-leave-status-chip [status]='paidEducationalLeaveAttest.statusId'
      ></alimento-ipv-frontend-paid-educational-leave-status-chip>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)='setActiveTabIndex($event)'
        [activeTabIndex]='activeTabIndex'
        [data]='tabMenuItems'
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div *ngIf='activeTabIndex === 0' [hidden]='activeTabIndex !== 0'>
        <div>
          <alimento-ipv-frontend-paid-educational-leave
            [paidEducationalLeaveAttest]='paidEducationalLeaveAttest'
            [contactPersons]='contactPersons'
            [branch]='branch'
            (updateContactPerson)='updateContactPerson($event)'
          ></alimento-ipv-frontend-paid-educational-leave>
        </div>

        <div>
          <div class='flex items-center gap-4'>
            <h3>{{ 'paidEducationalLeaves.sessions' | translate }}</h3>
            <span class='mb-2'>{{'trainings.sessions.totalDuration' | translate}}: {{totalHours | duration}}</span>
          </div>

          <alimento-ipv-frontend-paid-educational-leave-sessions [paidEducationalLeaveAttest]='paidEducationalLeaveAttest'
                                                                 (sessionUpdateEvent)='sessionUpdateEvent($event)'
          ></alimento-ipv-frontend-paid-educational-leave-sessions>
        </div>

        <div class='mt-2'>
          <alimento-ipv-frontend-page-detail-communications [contextType]='MailContextType.PaidEducationalLeave'
                                                            [contextValue]='paidEducationalLeaveId'
                                                            [readOnly]='readOnly'
                                                            [showFilters]='false'
          ></alimento-ipv-frontend-page-detail-communications>
        </div>
      </div>
    </ng-container>

    <ng-container metadata>
      <alimento-ipv-frontend-paid-educational-leave-action-dialog [action]='currentAction'
                                                                  (submitPopup)='executeAction($event)'
      ></alimento-ipv-frontend-paid-educational-leave-action-dialog>

      <div *ngIf='paidEducationalLeaveAttest?.statusId === PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.CANCELLED' class='flex flex-col'>
        <p-button
          label="{{ 'paidEducationalLeaves.actions.reactivate' | translate }}"
          variant='outlined'
          styleClass='w-full'
          loadingIcon="pi pi-spin pi-spinner"
          (click)="openActionDialog(PAID_EDUCATIONAL_LEAVE_ACTION.REACTIVATE)"
        ></p-button>
      </div>

      <div *ngIf='canRequestAttest' class='flex flex-col'>
        <p-button
          label="{{ 'paidEducationalLeaves.actions.attestRequested' | translate }}"
          variant='outlined'
          styleClass='w-full'
          loadingIcon="pi pi-spin pi-spinner"
          (click)="openActionDialog(PAID_EDUCATIONAL_LEAVE_ACTION.ATTEST_REQUESTED)"
        ></p-button>
      </div>

      <div *ngIf='canGenerate' class='flex flex-col'>
        <p-button
          label="{{ 'paidEducationalLeaves.actions.generate' | translate }}"
          variant='outlined'
          styleClass='w-full'
          loadingIcon="pi pi-spin pi-spinner"
          (click)="openActionDialog(PAID_EDUCATIONAL_LEAVE_ACTION.GENERATE)"
        ></p-button>
      </div>

      <div *ngIf='canSend' class='flex flex-col'>
        <p-button
          label="{{ 'paidEducationalLeaves.actions.send' | translate }}"
          variant='outlined'
          styleClass='w-full'
          loadingIcon="pi pi-spin pi-spinner"
          (click)="openActionDialog(PAID_EDUCATIONAL_LEAVE_ACTION.SEND)"
        ></p-button>
      </div>

      <div *ngIf='paidEducationalLeaveAttest?.statusId !== PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.GENERATED &&
      paidEducationalLeaveAttest?.statusId !== PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.CANCELLED'>
        <alimento-ipv-frontend-detail-actions label='trainings.actions.extra'
                                              [generateItems]='extraActions'
                                              (onClick)='setExtraActions()'
        ></alimento-ipv-frontend-detail-actions>
      </div>

      <alimento-ipv-frontend-metadata-item header='paidEducationalLeaves.schoolYear'
                                           [value]='schoolYear'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="branch"
                                           header='paidEducationalLeaves.branches'
                                           [isLink]='true'
                                           [link]='["/branches", branch.branchId]'
                                           [value]='branch.branchName'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="person"
                                           header='persons.person'
                                           [isLink]='true'
                                           [link]='["/persons", person.personId]'
                                           value='{{person.lastName}} {{person.firstName}}'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item header='persons.workStatus'
                                           [value]='workStatus'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item header='paidEducationalLeaves.educationalLeaveTitle'
                                           [value]='educationalLeaveTitle'
      ></alimento-ipv-frontend-metadata-item>

      <div *ngIf='paidEducationalLeaveAttest?.statusId === PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.CANCELLED'>
        <alimento-ipv-frontend-metadata-item [header]='"enrollments.cancelDialog.reasonCancelled" | translate'
                                             [value]='reasonCancelled'
        ></alimento-ipv-frontend-metadata-item>

        <alimento-ipv-frontend-metadata-item [header]='"enrollments.cancelDialog.comment" | translate'
                                             [value]='paidEducationalLeaveAttest?.cancelledComment'
        ></alimento-ipv-frontend-metadata-item>
      </div>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
