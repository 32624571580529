<div class='shadow-grey-2 shadow-md my-2 p-4 bg-white border border-input-border flex items-center w-full gap-4'>
  <div class='grid gap-4 items-center w-full
{{participant.employerId ? "grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_150px]" : "grid-cols-[minmax(0,_1fr)_150px]"}}'>
    <div class='flex flex-col gap-1 w-full'>
      <div class='flex flex-wrap items-center gap-x-4'>
        <div class='flex items-center gap-1'>
          <span class='font-bold text-lg' *ngIf='participant.alimentoId'>{{participant.alimentoId}} -</span>
          <span class='font-bold text-lg'>{{participant.lastName}} {{participant.firstName}}</span>
          <i class='{{participant.employmentStartDate ? "text-primary" : "text-warning"}} material-symbols-outlined'
             *ngIf='showPossibleTransitionInfo'>info</i>
        </div>
        <span *ngIf='participant.nationalIdentificationNumber'>{{participant.nationalIdentificationNumber | insz}}</span>
      </div>
    </div>

    <span *ngIf='participant.employerId'>{{participant?.employerName}}{{participant?.employerCity ? " - " + participant?.employerCity : ""}}</span>
    <div class='flex items-center gap-2'>
      <span>{{workStatus}}</span>
      <i *ngIf='participant?.enrollmentViaCevora'
         pTooltip='{{"enrollments.cevora" | translate}}'
         class='bg-alimento-green bg-opacity-30 px-1 rounded not-italic font-bold'>
        C
      </i>
    </div>
  </div>

  <div class="flex items-center gap-2 w-40 justify-end" *ngIf='!readOnly'>
    <p-button *ngIf='!valid'
              label='{{"enrollments.actions.validate" | translate}}'
              (onClick)='edit()'
    ></p-button>
    <i *ngIf='valid' class='font-material text-lg cursor-pointer' (click)='edit()'>edit</i>
  </div>
</div>
