import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DateMapper,
  emailPattern,
  FilterType, FormComponent,
  MyMessageService,
  SearchFilterValue,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { delay, first, forkJoin, of, Subscription } from 'rxjs';
import { EnrollmentRequestParticipant, ParticipantUpdateEvent } from '../../../types/enrollment.type';
import { Person, SearchPersonItem } from '../../../types/person.type';
import { PersonComponent } from '../../../persons/components/person/person.component';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { EmploymentService } from '../../../services/employment.service';
import { PersonService } from '../../../services/person.service';
import { WORK_STATUS } from '../../../types/person.enum';
import { SessionService } from '../../../services/session.service';
import { AbsenceReason } from '../../../types/reference-data.type';
import { EnrollmentService } from '../../../services/enrollment.service';

@Component({
    selector: 'alimento-ipv-frontend-enrollment-request-participant-popup',
    templateUrl: './enrollment-request-participant-popup.component.html',
    standalone: false
})
export class EnrollmentRequestParticipantPopupComponent implements OnChanges, OnDestroy {
  @Input()
  trainingStartDate?: Date;

  @Input()
  participant: EnrollmentRequestParticipant;

  @Input()
  showSearch = true;

  @Input()
  groupId?: string;

  @Input()
  disableUniquePersonValidation = false;

  @Input()
  dataOptional = false;

  @Input()
  readOnly = false;

  @Input()
  showAbsences = false;

  @Input()
  externalWebsite = false;

  @Output()
  formSubmit = new EventEmitter<ParticipantUpdateEvent>();

  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;
  showEmploymentStartDate = false;

  personFormControl = new FormControl<string>('existing');
  searchPersonFormControl = new FormControl<SearchPersonItem>(undefined);
  person?: Person;
  showInterimOfficeFields = false;
  workStatusReadOnly: string;
  interimOfficeReadOnly: string;
  workStatus$ = this.referenceDataService.getWorkStatuses();
  groupIdFilter: SearchFilterValue[] = [];

  private _subscriptions: (Subscription | undefined)[] = [];
  private _formSubscriptions: (Subscription | undefined)[] = [];
  readonly _INTERIM_PC_118 = 'a3bd8f32-ab6d-4749-8b1b-11ef962b5529';
  readonly _INTERIM_PC_220 = '4d8b315e-da9e-445f-ab54-68737fbfd1cb';

  @ViewChild(PersonComponent)
  personForm: PersonComponent;

  @ViewChild("interimAddress")
  interimAddressComponent?: FormComponent;

  absenceReasons: AbsenceReason[];
  registerAbsenceExpanded = false;

  constructor(private formBuilder: FormBuilder,
              private messageService: MyMessageService,
              private referenceDataService: ReferenceDataService,
              private employmentService: EmploymentService,
              private enrollmentService: EnrollmentService,
              private personService: PersonService,
              private sessionService: SessionService) {
    this._subscriptions.push(
      this.personFormControl.valueChanges.subscribe(() => {
        this.person = undefined;
        this.searchPersonFormControl.setValue(undefined);
      }),
      this.searchPersonFormControl.valueChanges.subscribe(personSearch => {
        if (personSearch) {
          this.personService.getPerson(personSearch.personId).pipe(first())
            .subscribe(person => {
              this.person = person;
              this.formGroup.controls['person'].patchValue({
                'lastName': person.lastName
              });
              this._setProfessionalData();
            });
        }
        else {
          this.person = undefined;
        }
        this._setProfessionalData();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['participant']?.currentValue) {
      this._createFormGroup();
      this.personFormControl.setValue(this.participant.id ? 'new' : 'existing');

      if (this.showAbsences) {
        this._setAbsences();
      }
    }
    if (changes['groupId']?.currentValue) {
      this.groupIdFilter = [{ type: FilterType.filterEnterpriseGroupId, values: [this.groupId] }];
    }
    if (changes['showSearch']?.currentValue !== undefined) {
      this.personFormControl.setValue(this.showSearch ? 'existing' : 'new');
    }
    if (changes['dataOptional']?.currentValue !== undefined) {
      this._setValidation();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
    this._formSubscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    this._createFormGroup();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._formSubscriptions.forEach(sub => sub?.unsubscribe());
    this._formSubscriptions = [];
    this.person = undefined;
    this.searchPersonFormControl.setValue(undefined);
  }

  private _isPersonValid(): boolean {
    if (this.externalWebsite || this.personFormControl.value === 'new') {
      return this.personForm.isValid();
    }
    else {
      return this.person !== undefined;
    }
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    this.formGroup.get("person").updateValueAndValidity();

    return this._isPersonValid() &&
      ((!this.externalWebsite || !this.showInterimOfficeFields) || (this.interimAddressComponent && this.interimAddressComponent.isValid())) &&
      this.formGroup.valid;
  }

  getFormData(): EnrollmentRequestParticipant {
    let data = JSON.parse(JSON.stringify(this.formGroup.value));

    delete data.person;
    if (this.externalWebsite || this.personFormControl.value === 'new') {
      data = { ...data, ...this.personForm.getData() };
      data.genderId = data.gender;
      delete data.gender;
      data.educationLevelId = data.educationLevel;
      delete data.educationLevel;
    }
    else {
      data = {
        ...data,
        ...{
          nationalIdentificationNumber: this.person?.nationalIdentificationNumber,
          firstName: this.person?.firstName,
          lastName: this.person?.lastName,
          dateOfBirth: this.person?.dateOfBirth,
          educationLevelId: this.person?.educationLevel?.data,
          genderId: this.person?.gender?.data,
          nationality: this.person?.nationality,
          language: this.person?.language,
          personId: this.person?.personId
        }
      };
    }

    if (!data.newInterimOffice) {
      delete data.interimOfficeAddress;
      delete data.interimOfficeName;
    }
    else {
      data.interimOfficeNumber = data.interimOfficeAddress.houseNumber;
      data.interimOfficeCountry = data.interimOfficeAddress.country;
      data.interimOfficeCity = data.interimOfficeAddress.city;
      data.interimOfficeStreet = data.interimOfficeAddress.street;
      data.interimOfficePostalCode = data.interimOfficeAddress.postalCode;
      data.interimOfficeMailbox = data.interimOfficeAddress.mailbox;
      delete data.interimOfficeAddress;
      delete data.interimOfficeId;
    }
    delete data.newInterimOffice;

    data.employmentStartDate = DateMapper.getDateFromDateTimeAsString(data.employmentStartDate);

    data.employerId = data.employer?.branchId || data.employer;
    delete data.employer;

    if (data.sessions?.length > 0) {
      data.absences = data.sessions.filter((session: any) => session.absent)
        .map((session: any) =>
          ({ sessionId: session.sessionId, reasonForAbsenceId: session.reasonForAbsenceId }));
    }
    delete data.sessions;

    data.id = this.participant?.id;

    return data;
  }

  addOrUpdateForm(): void {
    this._submitForm();
  }

  private _submitForm(): void {
    if (!this._isPersonValid()) {
      if (this.externalWebsite || this.personFormControl.value === 'new') {
        this.messageService.error('validation.personIncomplete');
      }
      else {
        this.messageService.error('validation.noPersonSelected');
      }
      return;
    }
    else if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.formSubmit.emit({
      participant: this.getFormData(),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private _createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      person: [{
        nationalIdentificationNumber: this.participant?.nationalIdentificationNumber,
        firstName: this.participant?.firstName,
        lastName: this.participant?.lastName,
        gender: this.participant?.genderId ? { data: this.participant?.genderId } : undefined,
        language: this.participant?.language,
        nationality: this.participant?.nationality,
        educationLevel: this.participant?.educationLevelId ? { data: this.participant?.educationLevelId } : undefined,
        dateOfBirth: this.participant?.dateOfBirth ? new Date(this.participant?.dateOfBirth) : undefined,
        street: undefined,
        houseNumber: undefined,
        mailbox: undefined,
        city: undefined,
        postalCode: undefined,
        country: undefined
      }],
      employer: [this.participant?.employerId, Validators.required],
      function: [this.participant?.function],
      workStatusId: [this.participant?.workStatusId, Validators.required],
      interimOfficeId: [this.participant?.interimOfficeId],
      interimOfficeContactFirstName: [this.participant?.interimOfficeContactFirstName],
      interimOfficeContactLastName: [this.participant?.interimOfficeContactLastName],
      interimOfficeContactEmail: [this.participant?.interimOfficeContactEmail, [emailPattern()]],
      newInterimOffice: [(!this.participant?.interimOfficeId && !!this.participant?.interimOfficeName) || false],
      interimOfficeName: [this.participant?.interimOfficeName],
      interimOfficeAddress: [{
        houseNumber: this.participant?.interimOfficeNumber || '',
        country: this.participant?.interimOfficeCountry || '',
        city: this.participant?.interimOfficeCity || '',
        street: this.participant?.interimOfficeStreet || '',
        postalCode: this.participant?.interimOfficePostalCode || '',
        mailbox: this.participant?.interimOfficeMailbox || ''
      }],
      employmentStartDate: [this.participant?.employmentStartDate ? new Date(this.participant?.employmentStartDate) : undefined],
      sessions: this.formBuilder.array([])
    });

    this._setValidation();
    this._addListeners();
    this._setShowInterimOfficeFields();
    this._updateInterimRequiredFields();
  }

  get sessions(): FormArray {
    return this.formGroup.get('sessions') as FormArray;
  }

  private _setValidation(): void {
    this.formGroup?.get('employer').setValidators(this.dataOptional ? [] : [Validators.required]);
    this.formGroup?.get('workStatusId').setValidators(this.dataOptional ? [] : [Validators.required]);
  }

  private _addListeners(): void {
    this._formSubscriptions.forEach((sub) => sub.unsubscribe());
    this._formSubscriptions = [];

    this._formSubscriptions.push(
      this.formGroup.get('workStatusId').valueChanges.pipe(delay(1))
        .subscribe(() => {
          this._setShowInterimOfficeFields();
          this._updateInterimRequiredFields();
        })
    );

    this._formSubscriptions.push(
      this.formGroup.get('employer').valueChanges.subscribe(() => this._setProfessionalData())
    );

    this._formSubscriptions.push(
      this.formGroup.get('newInterimOffice').valueChanges.subscribe(() => this._updateInterimRequiredFields())
    );

    this._formSubscriptions.push(
      this.formGroup.get('person').valueChanges.subscribe(() => this._setShowEmploymentStartDate()),
      this.formGroup.get('workStatusId').valueChanges.subscribe(() => this._setShowEmploymentStartDate())
    );
  }

  private _setShowInterimOfficeFields(): void {
    const workStatus = this.formGroup.get('workStatusId').value;
    this.showInterimOfficeFields = workStatus === this._INTERIM_PC_118 || workStatus === this._INTERIM_PC_220;
  }

  private _updateInterimRequiredFields(): void {
    const newInterimOffice = this.formGroup.get('newInterimOffice').value;

    ["interimOfficeContactFirstName", "interimOfficeContactLastName", "interimOfficeContactEmail"].forEach(field => {
      this.formGroup.get(field).setValidators(this.showInterimOfficeFields && this.externalWebsite ? Validators.required : []);
      this.formGroup.get(field).updateValueAndValidity();
    });

    ["interimOfficeId"].forEach(field => {
      this.formGroup.get(field).setValidators(this.showInterimOfficeFields && this.externalWebsite && !newInterimOffice ? Validators.required : []);
      this.formGroup.get(field).updateValueAndValidity();
    });

    ["interimOfficeName", "interimOfficeAddress"].forEach(field => {
      this.formGroup.get(field).setValidators(this.showInterimOfficeFields && this.externalWebsite && newInterimOffice ? Validators.required : []);
      this.formGroup.get(field).updateValueAndValidity();
    })
  }

  private _setProfessionalData(): void {
    if (this.formGroup?.get('employer')?.value && this.person) {
      const employerId = this.formGroup.get('employer').value?.branchId || this.formGroup.get('employer').value;
      this.employmentService.getEmploymentsOfPersonByBranch(employerId, this.person.personId)
        .pipe(first())
        .subscribe(employment => {
          if (employment) {
            this.formGroup.get('workStatusId').setValue(employment.workStatus?.data);
            this.formGroup.get('function').setValue(employment.function);
            this._setShowInterimOfficeFields();
            this._updateInterimRequiredFields();
          }
        });
    }
  }

  private _setShowEmploymentStartDate(): void {
    const workStatusId = this.formGroup.get('workStatusId').value;

    if ((workStatusId === WORK_STATUS.PC_118 || workStatusId === WORK_STATUS.PC_220) && this.trainingStartDate) {
      const age = DateMapper.getAge(new Date(this.formGroup.get('person').value?.dateOfBirth), new Date(this.trainingStartDate));
      this.showEmploymentStartDate = age <= 26 || age >= 50;
    }
    else {
      this.showEmploymentStartDate = false;
    }
  }

  getReasonLabel(value: string | undefined): string {
    if (value) {
      return this.absenceReasons.filter(reason => reason.data === value)[0]?.label || '';
    }
    return '';
  }

  private _setAbsences(): void {
    forkJoin([
      this.referenceDataService.getAbsenceReasons().pipe(first()),
      this.participant?.id ? this.enrollmentService.getEnrollmentRequestParticipantAbsences(this.participant.id).pipe(first()) : of([])
    ])
      .subscribe(([absenceReasons, absences]) => {
        this.absenceReasons = absenceReasons;

        this.sessionService.sessions().forEach(session => {
          const absence = absences.filter(absence => absence.sessionId === session.id)[0];
          const sessionGroup = this.formBuilder.group({
            sessionId: session.id,
            title: session.title,
            date: session.date,
            startTime: session.startTime,
            endTime: session.endTime,
            absent: [!!absence],
            reasonForAbsenceId: [absence?.reasonForAbsenceId]
          });
          this.sessions.push(sessionGroup);
          if (this.readOnly) {
            sessionGroup.disable({emitEvent: false});
          }

          this._subscriptions.push(
            sessionGroup.get('absent').valueChanges.subscribe((absent: boolean) => {
              sessionGroup.get('reasonForAbsenceId').setValidators(absent ? Validators.required : []);
              sessionGroup.get('reasonForAbsenceId').reset();
              sessionGroup.get('reasonForAbsenceId').updateValueAndValidity();
            })
          );
        });
        this.registerAbsenceExpanded = absences.length > 0
      });
  }
}
