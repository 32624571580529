import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { first } from 'rxjs';
import { EnrollmentRequestParticipant } from '../../../types/enrollment.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { WORK_STATUS } from '@alimento-ipv-frontend/application-lib';
import { DateMapper } from '@alimento-ipv-frontend/ui-lib';

@Component({
    selector: 'alimento-ipv-frontend-enrollment-request-participant-card',
    templateUrl: './enrollment-request-participant-card.component.html',
    standalone: false
})
export class EnrollmentRequestParticipantCardComponent implements OnChanges {
  @Input()
  participant: EnrollmentRequestParticipant;

  @Input()
  readOnly = false;

  @Input()
  valid = false;

  @Input()
  trainingStartDate?: Date;

  @Output()
  editClicked = new EventEmitter<void>();

  workStatus: string;
  showPossibleTransitionInfo: boolean;

  constructor(private referenceDataService: ReferenceDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['participant']?.currentValue) {
      if (this.participant?.workStatusId) {
        this.referenceDataService.getWorkStatus(this.participant.workStatusId).pipe(first())
          .subscribe(workStatus => this.workStatus = workStatus.label);
      }

      if ((this.participant.workStatusId === WORK_STATUS.PC_118 || this.participant.workStatusId === WORK_STATUS.PC_220) && this.trainingStartDate) {
        const age = DateMapper.getAge(new Date(this.participant?.dateOfBirth), new Date(this.trainingStartDate));
        this.showPossibleTransitionInfo = (age <= 26 || age >= 50);
      }
      else {
        this.showPossibleTransitionInfo = false;
      }
    }
  }

  edit(): void {
    this.editClicked.emit()
  }
}
