<div *ngIf='transition'
     (click)='this.expanded = !this.expanded'
     [ngClass]='{"!bg-primary-hover border-primary border": activeTransitionId === transition.id}'
     class="my-2 p-4 bg-white hover:bg-primary-hover relative cursor-pointer">
  <div class='flex items-start'>
    <div class='w-4 h-4 mr-4'>
      <div
        (keyup.enter)='this.expanded = !this.expanded'

        class='flex items-center py-2 text-primary outline-primary'
        tabindex='0'
      >
        <i class="pi pi-angle-{{ expanded ? 'up' : 'down' }}"></i>
      </div>
    </div>
    <div class='w-full grid grid-cols-2 gap-4 items-start'>
      <div class='flex flex-col gap-2'>
        <div class='flex items-center gap-4'>
          <span class='font-bold text-lg capitalize'>{{type?.label}}</span>
          <span class='text-primary' *ngIf='transition.transitionStatusId !== TRANSITION_STATE.ACTIVE'>{{status}}</span>
        </div>
        <span *ngIf='rubrics'>{{rubrics}}</span>
        <span *ngIf='transition.function && transition.transitionTypeId === TRANSITION_TYPE.FUNCTION_CHANGE'>
        {{transition.function}}
      </span>
      </div>
      <div class='flex flex-col items-end gap-2'>
        <span *ngIf='transition.validUntil && transition.validFrom'>
          {{'persons.transitions.validFromUntil' | translate:{
          from: transition.validFrom | date: "dd/MM/yyyy", until: transition.validUntil | date: "dd/MM/yyyy"} }}
        </span>
          <span *ngIf='transition.validFrom && !transition.validUntil'>
          {{"persons.transitions.validFrom" | translate}} {{transition.validFrom | date: "dd/MM/yyyy"}}
        </span>
          <span *ngIf='transition.validUntil && !transition.validFrom'>
          {{"persons.transitions.validTo" | translate}} {{transition.validUntil | date: "dd/MM/yyyy"}}
        </span>

        <div class='flex items-center gap-2'>
          <p-button *ngIf='transition.transitionStatusId === TRANSITION_STATE.DRAFT && !readOnly'
                    (onClick)="onActionClicked(TransitionActionEnum.activate)"
                    label="{{'persons.transitions.actions.activate' | translate}}"
          >
          </p-button>
          <p-button *ngIf='transition.transitionStatusId === TRANSITION_STATE.DRAFT && !readOnly'
                    (onClick)="onActionClicked(TransitionActionEnum.reject)"
                    styleClass='inverted-button with-border'
                    label="{{'persons.transitions.actions.reject' | translate}}"
          >
          </p-button>
        </div>
        <div class='flex items-center gap-4' *ngIf='transition.transitionStatusId === TRANSITION_STATE.ACTIVE || transition.transitionStatusId === TRANSITION_STATE.CLOSED'>
          <span>{{'persons.transitions.totalBudget' | translate}}: {{transition.totalBudget | currency:"EUR"}}</span>
          <span>{{'persons.transitions.remainingBudget' | translate}}: {{transition.remainingBudget | currency:"EUR"}}</span>
          <span>{{'persons.transitions.hoursFollowed' | translate: {hours: transition.numberOfHoursFollowed} }}</span>
        </div>
      </div>
    </div>
    <div class='w-16 flex justify-end'>
      <alimento-ipv-frontend-more-actions [actions]='actions'>
      </alimento-ipv-frontend-more-actions>
    </div>
  </div>

  <div *ngIf='transition.files?.length > 0' class='ml-8 mt-2'>
    <lib-documents [files]='transition.files'
                   [canAdd]='false'
                   [canDelete]='false'
                   [showFilesAsList]='false'
    ></lib-documents>
  </div>
</div>

<div *ngIf='transition && expanded' class='pr-[4.5rem] pl-12 pb-4 conditional-fields'>

  <h3>{{'persons.transitions.trainings' | translate}}</h3>

  <div *ngFor='let enrollment of transition.eligibleEnrollments'
       class='grid grid-cols-1 lg:grid-cols-[1fr_200px_1fr] py-4 border-b-grey-2 border-b last:border-b-0 last:pb-0'>
    <div class='flex flex-col items-start flex-wrap'>
      <a (click)='navigateToTraining(enrollment, $event)' class='font-bold hover:underline cursor-pointer'>
        {{enrollment.training.alimentoId}} - {{enrollment.training.customTitle}}
      </a>
      <span>{{getTrainingType(enrollment.training.typeId) | async}}</span>
    </div>
    <div>
      <div class='flex gap-2 items-center' *ngIf='enrollment.training.caseManagerName'>
        <i class='font-material text-lg'>person</i>
        <span>{{enrollment.training.caseManagerName}}</span>
      </div>
    </div>
    <div class='flex flex-col flex-wrap lg:items-end'>
      <span *ngIf='enrollment.training.startDate === enrollment.training.endDate'>
        {{enrollment.training.startDate | date: "dd/MM/yyyy"}}
      </span>
      <span *ngIf='enrollment.training.startDate !== enrollment.training.endDate'>>
        {{enrollment.training.startDate | date: "dd/MM/yyyy"}} - {{enrollment.training.endDate | date: "dd/MM/yyyy"}}
      </span>

      <div *ngIf='enrollment.reimbursements?.length > 0' class='flex items-start gap-4'>
        <div class='flex flex-col flex-wrap'>
          <span class='whitespace-nowrap' *ngIf='enrollment.reservedBudget'>
            {{"persons.transitions.reservedBudget" | translate}}: {{enrollment.reservedBudget | currency:"EUR"}}
          </span>
          <span class='whitespace-nowrap' *ngIf='enrollment.usedBudget'>
          {{"persons.transitions.usedBudget" | translate}}: {{enrollment.usedBudget | currency:"EUR"}}
        </span>
        </div>

        <span class='whitespace-nowrap'>{{"persons.transitions.hoursFollowed" | translate:{hours: enrollment.numberOfHoursFollowed} }}</span>
      </div>

      <span class="text-primary font-bold"
          *ngIf='!enrollment.reimbursements || enrollment.reimbursements.length === 0'>
        {{'persons.transitions.available' | translate}}
      </span>
    </div>
  </div>

  <div *ngIf='!transition.eligibleEnrollments || transition.eligibleEnrollments.length === 0'>
    <span>{{'persons.transitions.noTrainings' | translate}}</span>
  </div>
</div>
