<alimento-ipv-frontend-overview-page-layout header='persons.menuTitle'>
  <ng-container top-right>
    <p-button
      *hideIfAllUnauthorized='[Role.ADMIN, Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE]'
      label="{{ 'persons.actions.add' | translate }}"
      [routerLink]='["/persons/create"]'
    ></p-button>
  </ng-container>

  <p-tabs [(value)]='activeIndex'>
    <p-tablist>
      <p-tab value='0'>
        <span class='text-xl p-tabview-title'>{{'persons.menuTitle' | translate}}</span>
      </p-tab>
      <p-tab value='1'>
        <span class='text-xl p-tabview-title'>{{'persons.transitionsTitle' | translate}}</span>
      </p-tab>
    </p-tablist>

    <p-tabpanels>
      <p-tabpanel value='0'>
        <lib-search [data$]='searchData$'
                    [filters]='searchFilters'
                    [cardTemplate]='cardTemplate'
                    [expandAll]='false'
                    [showToggle]='true'
                    [beforeSearchRequest]='beforeSearchRequest'
                    [isToggled]='true'
                    toggleLabel='persons.toggle'
                    name='persons'
                    searchPlaceholderKey='persons.searchListPlaceholder'
                    availableResultsTranslateKey='persons.menuTitle'
        >
          <ng-template #cardTemplate let-item="item">
            <div class='my-2'>
              <alimento-ipv-frontend-person-card
                [person]='item'
                [showEmployments]='true'
                [routerLink]='["/persons", item.personId]'
                tabindex="0"
              ></alimento-ipv-frontend-person-card>
            </div>
          </ng-template>
        </lib-search>
      </p-tabpanel>

      <p-tabpanel value='1'>
        <lib-search [data$]='searchTransitionData$'
                    [filters]='searchTransitionFilters'
                    [cardTemplate]='transitionTemplate'
                    [expandAll]='false'
                    name='person-transitions'
                    searchPlaceholderKey='persons.searchListPlaceholder'
                    availableResultsTranslateKey='persons.transitionsTitle'
        >
          <ng-template #transitionTemplate let-item="item">
            <div class='my-2'>
              <alimento-ipv-frontend-transition-search-card [transition]='item'
                                                            [routerLink]='["/persons", item.employment.person.id]'
                                                            [queryParams]='{transitionId: item.id}'
                                                            tabindex='0'
              >
              </alimento-ipv-frontend-transition-search-card>
            </div>
          </ng-template>
        </lib-search>
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>
</alimento-ipv-frontend-overview-page-layout>
