import { NgModule } from '@angular/core';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { TrainingProgramCardComponent } from './components/training-program-card/training-program-card.component';
import { RouterLink } from '@angular/router';
import { BasicInfoComponent } from './components/basic-info/basic-info.component';
import { SummaryDescriptionComponent } from './components/summary-description/summary-description.component';
import { AddressComponent } from './components/address/address.component';
import { SubSectorsComponent } from './components/subsectors-dialog/sub-sectors.component';
import { LanguageCodeComponent } from './components/language-code/language-code.component';
import { BranchCardComponent } from './components/branch-card/branch-card.component';
import { BranchSelectComponent } from './components/branch-select/branch-select.component';
import { TrainingCardComponent } from './components/training-card/training-card.component';
import { EducationLevelDialogComponent } from './components/education-level-dialog/education-level-dialog.component';
import { EnterpriseSelectComponent } from './components/enterprise-select/enterprise-select.component';
import { BasicEnterpriseCardComponent } from './components/basic-enterprise-card/basic-enterprise-card.component';
import { EnterpriseCardComponent } from './components/enterprise-card/enterprise-card.component';
import { EnterpriseStatusChipComponent } from './components/enterprise-status-chip/enterprise-status-chip.component';
import { ContactPersonSelectComponent } from './components/contact-person-select/contact-person-select.component';
import {
  ReimbursementRequestSelectComponent
} from './components/reimbursement-request-select/reimbursement-request-select.component';
import {
  ReimbursementRequestCardComponent
} from './components/reimbursement-request-card/reimbursement-request-card.component';
import {
  ReimbursementRequestStatusChipComponent
} from './components/reimbursement-request-status-chip/reimbursement-request-status-chip.component';
import { DetailActionsComponent } from './components/detail-actions/detail-actions.component';
import { TrainingStatusChipComponent } from './components/training-status-chip/training-status-chip.component';
import { EnrollmentStatusChipComponent } from './components/enrollment-status-chip/enrollment-status-chip.component';
import {
  TrainingEnrollmentCardComponent
} from './components/training-enrollment-card/training-enrollment-card.component';

@NgModule({
  imports: [
    UiLibModule,
    RouterLink
  ],
  providers: [
    CanDeactivateGuard
  ],
  declarations: [
    BasicInfoComponent,
    SummaryDescriptionComponent,
    AddressComponent,
    SubSectorsComponent,
    LanguageCodeComponent,
    TrainingProgramCardComponent,
    BranchCardComponent,
    BranchSelectComponent,
    TrainingCardComponent,
    EducationLevelDialogComponent,
    EnterpriseSelectComponent,
    BasicEnterpriseCardComponent,
    EnterpriseCardComponent,
    EnterpriseStatusChipComponent,
    ContactPersonSelectComponent,
    ReimbursementRequestSelectComponent,
    ReimbursementRequestStatusChipComponent,
    ReimbursementRequestCardComponent,
    DetailActionsComponent,
    TrainingStatusChipComponent,
    EnrollmentStatusChipComponent,
    TrainingEnrollmentCardComponent
  ],
  exports: [
    TrainingProgramCardComponent,
    BasicInfoComponent,
    SummaryDescriptionComponent,
    AddressComponent,
    LanguageCodeComponent,
    BranchCardComponent,
    BranchSelectComponent,
    TrainingCardComponent,
    EducationLevelDialogComponent,
    EnterpriseSelectComponent,
    BasicEnterpriseCardComponent,
    EnterpriseCardComponent,
    EnterpriseStatusChipComponent,
    ContactPersonSelectComponent,
    ReimbursementRequestSelectComponent,
    ReimbursementRequestStatusChipComponent,
    ReimbursementRequestCardComponent,
    DetailActionsComponent,
    TrainingStatusChipComponent,
    EnrollmentStatusChipComponent,
    TrainingEnrollmentCardComponent
  ]
})
export class UtilsModule {
}
