<p-dialog
  (blur)="popupVisible = false"
  *ngIf='popupVisible'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  [closable]="false"
  appendTo="body"
  header="{{
    (financialDocument?.id ? 'financialDocuments.editFinancialDocument' : 'financialDocuments.new') | translate
  }}"
>
  <div *ngIf="financialDocument" [formGroup]="formGroup">
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      formField='financialDocumentTypeId'
                                      fieldKey='financialDocuments.type'
    >
      <p-select
        [autofocus]='true'
        [filter]='true'
        [options]='types | async'
        [showClear]='true'
        formControlName='financialDocumentTypeId'
        id='financialDocumentTypeId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='referenceRequired'
                                      formField='reference'
                                      fieldKey='financialDocuments.reference'
    >
      <input class="w-full"
             formControlName="reference"
             id="reference"
             pInputText
             type="text"
      />
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      fieldKey='financialDocuments.totalAmount'
                                      formField='amountWithoutPreparation'>
      <p-inputNumber #numberControl
                     inputStyleClass='w-full'
                     formControlName='amountWithoutPreparation'
                     [maxFractionDigits]='2'
                     (onKeyDown)='onKeyDown($event, numberControl)'
                     mode="currency"
                     currency="EUR"
                     locale='nl-BE'
                     id='amountWithoutPreparation'>
      </p-inputNumber>

    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='financialDocuments.preparationAmount'
                                      formField='amountPreparation'>
      <p-inputNumber #numberControl2
                     inputStyleClass='w-full'
                     formControlName='amountPreparation'
                     [maxFractionDigits]='2'
                     (onKeyDown)='onKeyDown($event, numberControl2)'
                     mode="currency"
                     currency="EUR"
                     locale='nl-BE'
                     id='amountPreparation'>
      </p-inputNumber>

    </alimento-ipv-frontend-form-field>

    <div class='mb-2'>
      <lib-documents [(files)]='files'
                     [canDelete]='true'
                     [bigIconSize]='true'
                     (filesChange)='filesChange($event)'
      ></lib-documents>
      <small *ngIf="formGroup.get('files').dirty && formGroup.get('files')?.errors?.['minLength']" class='p-error block'>
        {{ 'validation.minOneFile' | translate }}
      </small>
    </div>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='remark'
      fieldKey='financialDocuments.comments'
    >
      <textarea
        class="w-full"
        formControlName="remark"
        id="remark"
        pTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        *ngIf="financialDocument"
        [loading]="loading"
        (onClick)="addOrUpdateFinancialDocument()"
        label="{{ ( financialDocument.id ? 'financialDocuments.edit' : 'financialDocuments.create') | translate }}"
      ></p-button>
      <p-button
        (onClick)="closePopup()"
        label="{{ 'financialDocuments.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
